<template>
  <div class="mt-8">
    <!-- HEADER -->
    <v-row class="primaryCo2 pt-4">
      <v-col class="d-flex align-center ml-10">
        <img width="40" height="40" src="../assets/svg/ic_stats_white.svg">
        <span class="font-co2 font-size-co2-5 ml-5">ESTADÍSTICAS GENERALES</span>
      </v-col>
    </v-row>
    <!-- CONTENT -->
    <v-row>
      <v-col>
        <div v-if="!statOk" class="selectCo2 d-flex justify-center font-co2 font-size-co2-2">
          Lo sentimos. Contenido no disponible en estos momentos.
        </div>
        <div v-else>
          <iframe id="inlineFrameExample"
              title="General Stats Devices"
              width="100%"
              :height="maxHeightScreen"
              :src="urlStatDAC">
          </iframe>
        </div>
      </v-col>
    </v-row>
    <!-- DIALOG -->
    <DialogError ref="dialogError" />
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay>     
  </div>
</template>

<script>
  import config from '../config.json';
  import axios from "axios";
  export default {
    name: 'stats',
    data () {
      return {
        urlCo2Organizations: config.host + config.apiUrl + config.urlOrganizations,
        urlCo2Users: config.host + config.apiUrl + config.urlUsers,
        maxHeightScreen: '',
        waitLoadScreen: false,
        textLoader: '',
        urlStatDAC: '',
        titleDialog: '',
        textDialog: '',
        statOk: true // cambiar a false cuando se pase la url final
      }
    },
    mounted(){
      let mhd = screen.height * 0.715;
      this.maxHeightScreen = mhd + 'px';

      this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
      if(this.userLocal.rol == 'organization'){
        this.loadDataUrl(this.urlCo2Organizations);
      }
      if(this.userLocal.rol == 'user'){
        this.loadDataUrl(this.urlCo2Users);
      }
      else{
        this.urlStatDAC = "https://app.deepint.net/shared/0000017c98d80c77-32691779-f078deb0-77edc3d4/dashboards/0000017cdff94e0b-94202366-89a3ed7c-a28f166f?lang=es";
      }
      
    },
    components: {
      DialogError: () => import("../components/DialogError"),
    },
    methods:{
      async loadDataUrl(urlRol){
        this.waitLoadScreen = true;
        this.textLoader = "Cargando...";

        this.collectDevices = await axios.get(urlRol + this.userLocal.id,         
          {
            headers:{
              "Content-Type": "application/json",
            }
          }
        )
        .then(authoritation => {
            this.urlStatDAC = authoritation.data.url;
            this.waitLoadScreen = false;
            this.statOk = true;
            return authoritation;
        })
        .catch(function(error){
            console.error(error);
            return undefined;
        });

        if(!this.collectDevices){
          this.waitLoadScreen = false;
          this.statOk = false;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado en la colección: devices";
          this.showDialogErrorGeneral();
        }
      },
      // DIALOG
      async showDialogErrorGeneral() {
        try {
          await this.$refs.dialogError.open(
            this.titleDialog,
            this.textDialog
          )
        } catch (error) {
          console.error(error);
        }
      },
    }
  }
</script>