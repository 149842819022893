<template>
  <div class="home">
    <v-app>
      <v-main>
        <!-- MENU HEADER -->
        <menu-bar v-model="drawer" :valueNoti="drawerNoti" @respondMenuBar="refreshMenubar" @respondMenuBarNoti="refreshNoti" />
        <!-- MENU SIDEBAR  -->
        <side-bar-menu v-model="drawer" />
        <sidebar-notifications v-model="drawerNoti" />
        <!-- CONTENT VIEWS -->
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__slideInLeft"
        >
          <router-view v-model="openCloseMap" />
        </transition>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import MenuBar from '../components/MenuBar.vue';
import SideBarMenu from '../components/SideBarMenu.vue';
import SidebarNotifications from '../components/SidebarNotifications.vue';

export default {
  name: 'home',
  data(){
    return{
      drawer: false,
      drawerNoti: false,
    }
  },
  components: {
    MenuBar,
    SideBarMenu,
    SidebarNotifications
  },
  computed: {
    openCloseMap: function(){
      if(this.drawer == true || this.drawerNoti == true){
        return true;
      }
      else{
        return false;
      }
    }
  },
  methods: {
    refreshMenubar(e){
      this.drawer = e;
    },
    refreshNoti(e){
      this.drawerNoti = e;
    },
  }
}
</script>
