<template>
  <div>
    <v-app-bar class="ma-0 pa-0" app>
      <v-row style="height: 110px">
        <v-col cols="2" class="bg-panel-menu">
          <v-btn class="mt-4" text style="height: 100%" @click.stop="drawer = !drawer">
            <v-img src="../assets/svg/ic_menu_bar_white.svg" height="50" contain></v-img>
          </v-btn>  
        </v-col>
        <v-col cols="10" class="bg-topbar">
          <v-row class="ma-0 pa-0 d-flex justify-center">
            <v-col cols="6" class="ma-0 pa-0"><v-img class="ml-auto" style="width:40%; height:160%" src="../assets/img/banner-air-CO2.png" aspect-ratio="9" contain></v-img></v-col>
            <v-col cols="6" class="ma-0 pa-0"><v-img class="mr-auto" style="width:40%; height:160%" src="../assets/img/logo_ayuntamiento_salamanca.png" aspect-ratio="9" contain></v-img></v-col>
          </v-row>
        </v-col>
        <!--
        <v-col cols="2" class="bg-panel-menu">
          <v-btn text style="height: 100%" @click.stop="drawerNoti = !drawerNoti">
            <v-img src="../assets/svg/ic_notification_white.svg" aspect-ratio="3" contain></v-img>
          </v-btn>
        </v-col>
        -->
      </v-row>
    </v-app-bar>        
  </div>
</template>

<script>
export default {
  name: 'MenuBar',
  data(){
    return{}
  },
  props: {
    value: Boolean,
    valueNoti: Boolean
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("respondMenuBar", value);
      },
    }, 
    drawerNoti: {
      get() {
        return this.valueNoti;
      },
      set(valueNoti) {
        this.$emit("respondMenuBarNoti", valueNoti);
      },
    },   
  },
}
</script>