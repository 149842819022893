<template>
  <div>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panelPos" focusable>
          <!-- ADD NEW NOTI -->
          <v-expansion-panel>
            <v-expansion-panel-header hide-actions>
              <div class="d-flex align-center">
                <span class="mr-4"><img width="24" height="24" src="../assets/svg/ic_notification_color_add.svg"></span>
                <span>AÑADIR NUEVA NOTIFICACIÓN</span> 
              </div> 
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="font-co2 font-size-co2-2 mt-4">Rellenar todos los campos para añadir una nueva notificación</div>
              <v-form id="idFormNewAlert" ref="formNewAlert" v-model="validNewAlert" lazy-validation>
                <v-row>
                  <v-col>
                    <v-row class="mt-4">
                      <v-col>
                        <v-text-field
                          id="idNameNewNotiName"
                          class="text-input-white"
                          type="text"
                          v-model="newNotiName"
                          color="#094353"
                          label="Introducir nombre de la notificación"
                          placeholder="Ej: Nivel alto de CO2"
                          :rules="[rules.required]"
                          required
                        ></v-text-field>    
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                          id="idNewNotiDescrip"
                          class="text-input-white"
                          v-model="newNotiDescrip"
                          color="#094353"
                          label="Descripción"
                          placeholder="Introducir breve descripción"
                          :rules="[rules.required]"
                          required
                        ></v-textarea>                    
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          id="idNewConditionOne"
                          v-model="newNotiType"
                          :items="listAllConditions"
                          color="#094353"
                          label="Seleccionar un tipo de condición"
                          item-text="name"
                          return-object
                          @change="prepareListOperations"
                        ></v-autocomplete> 
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-if="newNotiType != undefined && newNotiType != '' && newNotiType.tag !='one'" class="my-auto">
                        <v-btn elevation="0" class="btn-accept font-co2" v-on:click="addNewAlertOperation">
                          + Añadir condición
                        </v-btn>
                      </v-col>
                    </v-row>

                    <div v-if="newNotiType != undefined && newNotiType != ''">
                      <v-row v-for="(item, index) in arrayNewConditionAlerts" :key="item.id">
                        <v-col>
                          <v-card class="card-options-style px-6">
                            <v-row>
                              <v-col>
                                <v-autocomplete
                                  id="idNewUnit"
                                  v-model="arrayNewConditionAlerts[index].unit"
                                  :items="listAllUnits"
                                  color="#094353"
                                  label="Seleccionar un tipo de unidad"
                                  item-text="name"
                                  return-object
                                ></v-autocomplete> 
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  id="idNewOperation"
                                  v-model="arrayNewConditionAlerts[index].operation"
                                  :items="listAllOperations"
                                  color="#094353"
                                  label="Seleccionar un tipo de operación"
                                  item-text="name"
                                  return-object
                                ></v-autocomplete>                           
                              </v-col>
                              <v-col>
                                <v-text-field
                                  id="idNewValue"
                                  v-model="arrayNewConditionAlerts[index].value"
                                  color="#094353"
                                  label="Introducir valor de la condición"
                                  placeholder="Ej: 4"
                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                ></v-text-field>                           
                              </v-col> 
                              <v-col v-if="newNotiType.tag !='one'" class="my-auto d-flex justify-center" cols="1">
                                <v-btn elevation="0" text v-on:click="removeNewChild(index)">
                                  <img width="24" height="24" src="../assets/svg/ic_remove_red.svg">
                                </v-btn>
                              </v-col>                             
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn elevation="0" class="btn-accept font-co2" v-on:click="registerNewAlert">
                      AÑADIR NUEVA NOTIFICACIÓN
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- MODIFY NOTI -->
          <v-expansion-panel>
            <v-expansion-panel-header hide-actions>
              <div class="d-flex align-center">
                <span class="mr-4"><img width="24" height="24" src="../assets/svg/ic_notification_color_modify.svg"></span>
                <span>MODIFICAR NOTIFICACIÓN</span> 
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="font-co2 font-size-co2-2 mt-4">Seleccionar una notificación de la lista y modificar sus campos. Pulsar "GUARDAR CAMBIOS" para confirmar las modificaciones</div>
              <v-form id="idFormModify" ref="formMofify" v-model="validMofify" lazy-validation>
                <v-row class="mt-4">
                  <v-col>
                    <v-autocomplete
                      id="idNameNotiMod"
                      class="text-input-white"
                      v-model="selectNotiMod"
                      :items="listNotis"
                      color="#094353"
                      label="Introducir nombre de la notificación"
                      item-text="name"
                      return-object
                    ></v-autocomplete> 
                  </v-col>
                  <v-col v-if="selectNotiMod != undefined && selectNotiMod != ''">
                    <v-text-field
                      id="idModNoti"
                      class="text-input-white"
                      type="text"
                      v-model="selectNotiMod.id"
                      color="#094353"
                      label="Seleccionar un ID de notificación"
                      placeholder="Ej: 0000000a111111bb-222cc33d-e4444444-ffff5555"
                      @change="changesControl(selectNotiMod)"
                    ></v-text-field>                 
                  </v-col>
                </v-row>
                <v-row v-if="selectNotiMod != undefined && selectNotiMod != ''">
                  <v-col>
                    <v-textarea
                      id="idDescripNoti"
                      class="text-input-white"
                      v-model="selectNotiMod.descrip"
                      color="#094353"
                      label="Descripción"
                      placeholder="Introducir breve descripción"
                      @change="changesControl(selectNotiMod)"
                    ></v-textarea>                    
                  </v-col>
                </v-row>
                <div v-if="selectNotiMod != undefined && selectNotiMod != ''">
                  <v-row v-if="selectNotiMod.condition.type.tag == 'one'">
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-autocomplete
                            id="idModConditionOne"
                            v-model="selectNotiMod.condition.type"
                            :items="listAllConditions"
                            color="#094353"
                            label="Seleccionar un tipo de condición"
                            item-text="name"
                            return-object
                            @change="condictionControl(selectNotiMod)"
                          ></v-autocomplete> 
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card class="card-options-style px-6">
                            <v-row v-if="selectNotiMod.condition.children[0] != undefined">
                              <v-col>
                                <v-autocomplete
                                  id="idModUnitOne"
                                  v-model="selectNotiMod.condition.children[0].unit"
                                  :items="listAllUnits"
                                  color="#094353"
                                  label="Seleccionar un tipo de unidad"
                                  item-text="name"
                                  return-object
                                  @change="changesControl(selectNotiMod)"
                                ></v-autocomplete> 
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  id="idModOperationOne"
                                  v-model="selectNotiMod.condition.children[0].operation"
                                  :items="listAllOperations"
                                  color="#094353"
                                  label="Seleccionar un tipo de operación"
                                  item-text="name"
                                  return-object
                                  @change="changesControl(selectNotiMod)"
                                ></v-autocomplete>                           
                              </v-col>
                              <v-col>
                                <v-text-field
                                  id="idModValueOne"
                                  v-model="selectNotiMod.condition.children[0].value"
                                  color="#094353"
                                  label="Introducir valor de la condición"
                                  placeholder="Ej: 4"
                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                  @change="changesControl(selectNotiMod)"
                                ></v-text-field>                           
                              </v-col>                              
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="selectNotiMod.condition.type.tag == 'anyof'">
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-autocomplete
                            id="idModConditionAnyof"
                            v-model="selectNotiMod.condition.type"
                            :items="listAllConditions"
                            color="#094353"
                            label="Seleccionar un tipo de condición"
                            item-text="name"
                            return-object
                            @change="condictionControl(selectNotiMod)"
                          ></v-autocomplete>                           
                        </v-col>
                        <v-col class="my-auto">
                          <v-btn elevation="0" class="btn-accept font-co2" v-on:click="addNewOperation(selectNotiMod)">
                            + Añadir condición
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card class="card-options-style" v-for="child in selectNotiMod.condition.children" :key="child.id">
                            <v-row class="mx-4" v-if="child != undefined">
                              <v-col>
                                <v-autocomplete
                                  id="idModUnitAnyof"
                                  class="text-input-white"
                                  v-model="child.unit"
                                  :items="listAllUnits"
                                  color="#094353"
                                  label="Seleccionar un tipo de unidad"
                                  @change="changesControl(selectNotiMod)"
                                ></v-autocomplete> 
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  id="idModOperationAnyof"
                                  class="text-input-white"
                                  v-model="child.operation"
                                  :items="listAllOperations"
                                  color="#094353"
                                  label="Seleccionar un tipo de operación"
                                  item-text="name"
                                  return-object
                                  @change="changesControl(selectNotiMod)"
                                ></v-autocomplete>                           
                              </v-col>
                              <v-col>
                                <v-text-field
                                  id="idModValueAnyof"
                                  class="text-input-white"
                                  v-model="child.value"
                                  color="#094353"
                                  label="Introducir valor de la condición"
                                  placeholder="Ej: 4"
                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                  @change="changesControl(selectNotiMod)"
                                ></v-text-field>                           
                              </v-col>
                              <v-col class="my-auto d-flex justify-center" cols="1">
                                <v-btn elevation="0" text v-on:click="removeChild(selectNotiMod, child)">
                                  <img width="24" height="24" src="../assets/svg/ic_remove_red.svg">
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="selectNotiMod.condition.type.tag == 'allof'">
                    <v-col>
                      <v-row>
                          <v-col>
                          <v-autocomplete
                            id="idModConditionAllof"
                            v-model="selectNotiMod.condition.type"
                            :items="listAllConditions"
                            color="#094353"
                            label="Seleccionar un tipo de condición"
                            item-text="name"
                            return-object
                            @change="condictionControl(selectNotiMod)"
                          ></v-autocomplete>                      
                        </v-col>
                        <v-col class="my-auto">
                          <v-btn elevation="0" class="btn-accept font-co2" v-on:click="addNewOperation(selectNotiMod)">
                            + Añadir condición
                          </v-btn>
                        </v-col>                      
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card class="card-options-style" v-for="child in selectNotiMod.condition.children" :key="child.id">
                            <v-row class="mx-4" v-if="child != undefined">
                              <v-col>
                                <v-autocomplete
                                  id="idModUnitAllof"
                                  class="text-input-white"
                                  v-model="child.unit"
                                  :items="listAllUnits"
                                  color="#094353"
                                  label="Seleccionar un tipo de unidad"
                                  @change="changesControl(selectNotiMod)"
                                ></v-autocomplete> 
                              </v-col>
                              <v-col>
                                <v-autocomplete
                                  id="idModOperationAllof"
                                  class="text-input-white"
                                  v-model="child.operation"
                                  :items="listAllOperations"
                                  color="#094353"
                                  label="Seleccionar un tipo de operación"
                                  item-text="name"
                                  return-object
                                  @change="changesControl(selectNotiMod)"
                                ></v-autocomplete>                           
                              </v-col>
                              <v-col>
                                <v-text-field
                                  id="idModValueAllof"
                                  class="text-input-white"
                                  v-model="child.value"
                                  color="#094353"
                                  label="Introducir valor de la condición"
                                  placeholder="Ej: 4"
                                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 32 && event.charCode <= 57"
                                  @change="changesControl(selectNotiMod)"
                                ></v-text-field>                           
                              </v-col>
                              <v-col class="my-auto d-flex justify-center" cols="1">
                                <v-btn elevation="0" text v-on:click="removeChild(selectNotiMod, child)">
                                  <img width="24" height="24" src="../assets/svg/ic_remove_red.svg">
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- REMOVE NOTI -->
          <v-expansion-panel>
            <v-expansion-panel-header hide-actions>
              <div class="d-flex align-center">
                <span class="mr-4"><img width="24" height="24" src="../assets/svg/ic_notification_color_remove.svg"></span>
                <span>ELIMINAR NOTIFICACIÓN</span> 
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="font-co2 font-size-co2-2 my-4">Seleccionar una notificación y pulsar "ELIMINAR"</div>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="table-header-devices text-left">ID</th>
                      <th class="table-header-devices text-left">Nombre</th>
                      <th class="table-header-devices text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in listNotis" :key="item.id">
                      <td class="font-co2 font-size-co2-3">{{ item.id }}</td>
                      <td class="font-co2 font-size-co2-3">{{ item.name }}</td>
                      <td>
                        <v-btn elevation="0" class="btn-red font-co2" v-on:click="removeAlert(item)">
                          <div class="pos-icon-btn-left"><img width="24" height="24" src="../assets/svg/ic_notification_white_remove.svg"></div>
                          ELIMINAR
                        </v-btn> 
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>              
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <!-- DIALOG --> 
    <DialogError ref="dialogError" />
    <DialogSuccessful ref="dialogSuccessful" /> 
    <DialogRemoveAlert ref="dialogRemoveAlert" @respondRemoveAlert="removeNotification" /> 
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay> 
  </div>
</template>

<script>
import allList from '../allList.json';
import config from '../config.json';
import axios from "axios";
  export default {
    name: 'configNotifications',
    data () {
      return {
        urlCo2: config.host + config.apiUrl,
        waitLoadScreen: false,
        textLoader: '',
        titleDialog: '',
        textDialog: '',
        userLocal: undefined,
        panelPos: [],
        validMofify: true,
        validNewAlert: true,
        rules: {
          required: value => !!value || 'Campo obligatorio'
        },
        selectNotiMod: undefined,
        listNotis: [],
        listNotisInit: [],
        selectConditionMod: '',
        listAllConditions: allList.conditions,
        listAllUnits: allList.units,
        listAllOperations: allList.operations,
        newNotiName: '',
        newNotiDescrip: '', 
        newNotiType: '',  
        arrayNewConditionAlerts: [], 
        newNotiChildUnit: '', 
        newNotiChildOperation: '', 
        newNotiChildValue: '',
      }
    },
    props: {
      restart: Boolean,
      save: Boolean,
      uploadData: Object
    },
    components: {
      DialogError: () => import("../components/DialogError"),
      DialogSuccessful: () => import("../components/DialogSuccessful"),
      DialogRemoveAlert: () => import("../components/DialogRemoveAlert"),
    },
    watch: {
      async restart(){
        if (this.restart == true) {
          if(this.selectNotiMod != undefined){
            let nameSelect = this.selectNotiMod.name;
            this.selectNotiMod = undefined;
            await this.init();
            this.updateSelectNoti(nameSelect);             
          }
          else{
            this.$emit('updateNumChanges', 0);
            this.$emit('restartFalse');
          }
        }
      },
      async save(){
        if (this.save == true) {
          this.saveDataNoti();
        }
      },
    },
    created(){
      if(this.uploadData.configActiveNotis == false){
        this.init();
        this.$emit('saveInfoNotifications', { active: false, saveNotis: [], saveNotisInit: [] });
      }
      else{
        this.listNotis.push(...this.uploadData.configNotisAux.map(x => Object.assign({},x)));
        this.listNotisInit.push(...this.uploadData.configNotisInitAux.map(x => Object.assign({},x)));
      }
    },
    destroyed(){
      this.$emit('saveInfoNotifications', { active: true, saveNotis: this.listNotis, saveNotisInit: this.listNotisInit });
    },
    methods:{
      async init(){
        this.listNotis = [];
        this.listNotisInit = [];
        this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
        let flagError = false;
        this.waitLoadScreen = true;
        this.textLoader = 'cargando notificaciones...'
        let resultCollection = await axios.get(this.urlCo2 + config.urlOrganizations + this.userLocal.id + config.urlAlerts,         
          {
            headers:{
              "Content-Type": "application/json"
            }
          }
        )
        .then(async authoritation => {
            await this.getListsConditions(authoritation.data);
            this.waitLoadScreen = false;
        })
        .catch(function(error){
            console.error(error);
            flagError = true;
            return undefined;
        });
        if(flagError || resultCollection){
          this.waitLoadScreen = false;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado en la colección de alerts";
          this.showDialogErrorGeneral();
        }
        
      },
      async getListsConditions(mainInfo){
        for await (const elem of mainInfo) {
          await this.getInfoConditionId(elem.id);
        }
      },
      async getInfoConditionId(id){
        let flagError = false;
        this.waitLoadScreen = true;
        this.textLoader = 'cargando información...'
        let resultCollection = await axios.get(this.urlCo2 + config.urlOrganizations +this.userLocal.id + config.urlAlerts + id,         
          {
            headers:{
              "Content-Type": "application/json"
            }
          }
        )
        .then(async authoritation => {
          let infoAlert = authoritation.data;
          let typeFinal = {};
          if(infoAlert.condition.type == 'one'){
            typeFinal = { id: 0, name: "Cumple una condición", tag: "one" };
          }
          else if(infoAlert.condition.type == 'anyof'){
            typeFinal = { id: 1, name: "Cumple alguna condición", tag: "anyof" };
          }
          else if(infoAlert.condition.type == 'allof'){
            typeFinal = { id: 2, name: "Se cumplen todas las condiciones", tag: "allof" };
          }
          else{
            console.error("ERROR: El tipo de la condición es desconocido: ", infoAlert.id);
          }
          let childrenFinal = [];
          for await (const elem of infoAlert.condition.children) {
            let operationFinal = {};
            if(elem.operation == 'eq'){
              operationFinal = { id: 0, name: "Igual que (=)", tag: "eq" };
            }
            else if(elem.operation == 'gt'){
              operationFinal = { id: 1, name: "Mayor que (>)", tag: "gt" };
            }
            else if(elem.operation == 'ge'){
              operationFinal = { id: 2, name: "Mayor o igual que (>=)", tag: "ge" };
            }
            else if(elem.operation == 'lt'){
              operationFinal = { id: 3, name: "Menor que (<)", tag: "lt" };
            }
            else if(elem.operation == 'le'){
              operationFinal = { id: 4, name: "Menor o igual que (<=)", tag: "le" };
            }
            else{
              console.error("ERROR: El tipo de la operación ", elem.id ," es desconocido: ", infoAlert.id);
            }

            let unitFinal = {}
            if(elem.unit === 'CO2'){
              unitFinal = { id: 0, name: "CO2" };
            }
            else if(elem.unit === 'Temperatura'){
              unitFinal = { id: 1, name: "Temperatura" };
            }
            else if(elem.unit === 'Presión atmosférica'){
              unitFinal = { id: 2, name: "Presión atmosférica" };
            }
            else if(elem.unit === 'Humedad'){
              unitFinal = { id: 3, name: "Humedad" };
            }
            else{
              console.error("ERROR: El tipo de la unidad es desconocido: ", elem);
            }
            childrenFinal.push(
              {
                id: elem.id,
                operation: operationFinal,
                unit: unitFinal,
                value: elem.value
              }
            );
          }
          let childrenArray = []
          childrenArray.push(...childrenFinal.map(x => Object.assign({},x)))
          this.listNotis.push(
            {
              id: infoAlert.id,
              created: infoAlert.creationDate,
              modify: infoAlert.modifyDate,
              name: infoAlert.name,
              descrip: infoAlert.description,
              condition: {
                type: Object.assign({},typeFinal),
                children: childrenArray
              }
            }
          );
          childrenArray = []
          childrenArray.push(...childrenFinal.map(x => Object.assign({},x)))
          this.listNotisInit.push(
            {
              id: infoAlert.id,
              created: infoAlert.creationDate,
              modify: infoAlert.modifyDate,
              name: infoAlert.name,
              descrip: infoAlert.description,
              condition: {
                type: Object.assign({},typeFinal),
                children: childrenArray
              }
            }
          );
          this.waitLoadScreen = false;
        })
        .catch(function(error){
            console.error(error);
            flagError = true;
            return undefined;
        });
        if(flagError || resultCollection){
          this.waitLoadScreen = false;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado en la colección de alerts con nombre: " + id;
          this.showDialogErrorGeneral();
        }  
      },
      addNewOperation(selectNotiMod){
        let nextId = 0;
        nextId = Number(selectNotiMod.condition.children.length);
        selectNotiMod.condition.children.push(
          {
            id: nextId,
            operation: { id: 0, name: "Igual que (=)", tag: "eq" },
            unit: { id: 0, name: "CO2" },
            value:  1 
          },
        );
        this.changesControl(selectNotiMod);
      },
      removeChild(selectNotiMod, child){
        for (const i in selectNotiMod.condition.children) {
          if (selectNotiMod.condition.children[i].id == child.id) {
            selectNotiMod.condition.children.splice(i, 1);
          }
        }
        this.changesControl(selectNotiMod);
      },
      changesControl(data){
        let count = 0;
        for (const elem of this.listNotisInit) {
          if(elem.name == data.name){
            if(elem.id != data.id){
              count++;
            }
            if(elem.descrip != data.descrip){
              count++;
            }
            if(elem.condition.type.tag != data.condition.type.tag){
              count++;
            }
            else{
              if(elem.condition.children.length != data.condition.children.length){
                let res = Math.abs(Number(data.condition.children.length) - Number(elem.condition.children.length));
                count += res;
              }
              if(elem.condition.children.length == data.condition.children.length){
                for (const childData of data.condition.children) {
                  let flag = false;
                  for (const childElem of elem.condition.children) {
                    if(childData.id == childElem.id){
                      flag = true;
                    }
                  }
                  if(flag == false){
                    count++;
                  }
                }
              }
              for (const childElem of elem.condition.children) {
                if(childElem != undefined){
                  for (const childData of data.condition.children) {
                    if(childData.id == childElem.id){
                      if(childData.operation.name != childElem.operation.name){
                        count++;
                      }
                      if(childData.unit != childElem.unit){
                        count++;
                      }
                      if(childData.value != childElem.value){
                        count++;
                      } 
                    }
                  }                
                }
              }              
            }
          }
        }
        this.$emit('updateNumChanges', count);
      },
      condictionControl(selectNotiMod){
        for (const init of this.listNotisInit) {
          if(init.id == selectNotiMod.id){
            if(init.condition.type.name == selectNotiMod.condition.type.name){
              selectNotiMod.condition.children = init.condition.children;
            }
            else{
              if(selectNotiMod.condition.type.tag == 'one'){
                selectNotiMod.condition.children = [
                  {
                    id: 0,
                    operation: { id: 0, name: "Igual que (=)", tag: "eq" },
                    unit: { id: 0, name: "CO2" },
                    value:  5                  
                  }
                ]
              }
              else{
                selectNotiMod.condition.children = [];
              }
            }
          }
        }
        this.changesControl(selectNotiMod);
      },
      updateSelectNoti(nameSelect){
        for (const item of this.listNotis) {
          if (item.name == nameSelect) {
            this.selectNotiMod = item;
          }
        }
        this.$emit('updateNumChanges', 0);
        this.$emit('restartFalse');
      },      
      async saveDataNoti(){
        this.waitLoadScreen = true;
        this.textLoader = "Guardando cambios en la configuración...";
        let bodyDB = {};
        let childPut = [];
        for (const elem of this.listNotis) {
          if(elem.id == this.selectNotiMod.id){
            for (const child of elem.condition.children) {
              childPut.push({
                operation: child.operation.tag,
                unit: child.unit.name,
                value: child.value
              });
            }
            bodyDB = {
              name: elem.name,
              description: elem.descrip,
              condition: {
                type: elem.condition.type.tag,
                children: childPut
              }
            };
          }
        }
        let resultCollection = await axios.put(this.urlCo2 + config.urlOrganizations + this.userLocal.id + config.urlAlerts + this.selectNotiMod.id,
          bodyDB,
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
          this.waitLoadScreen = false;
          this.listNotisInit = [];
          this.listNotisInit.push(...this.listNotis.map(x => Object.assign({},x)));
          this.$emit('updateNumChanges', 0);
          this.$emit('saveFalse');
          return authoritation;
        })
        .catch(function(error){
          console.error(error);
          return undefined;
        });
        if(!resultCollection){
          this.waitLoadScreen = false;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado en la colección de guardar configuración";
          this.showDialogErrorGeneral();
        }
      },
      removeNotification(e){
        for (const [i, elem] of this.listNotis.entries()) {
          if(elem.id == e.id){
            this.listNotis.splice(i, 1);
            this.listNotisInit.splice(i, 1);
          }
        }
      },
      prepareListOperations(){
        this.arrayNewConditionAlerts = [
          {
            id: 0,
            operation: { id: 0, name: "Igual que (=)", tag: "eq" },
            unit: { id: 0, name: "CO2" },
            value:  1                  
          }
        ];
      },
      addNewAlertOperation(){
        if(this.newNotiType.tag != 'one'){
          let nextId = 0;
          nextId = Number(this.arrayNewConditionAlerts.length);
          this.arrayNewConditionAlerts.push(
            {
              id: nextId,
              operation: { id: 0, name: "Igual que (=)", tag: "eq" },
              unit: { id: 0, name: "CO2" },
              value:  1           
            }
          );   
        }
      },
      removeNewChild(index){
        this.arrayNewConditionAlerts.splice(index, 1);
      },
      async registerNewAlert(){
        if(this.$refs.formNewAlert.validate()){
          this.waitLoadScreen = true;
          this.textLoader = "Guardando notificación...";
          let childPost = [];
          for (const elem of this.arrayNewConditionAlerts) {
            childPost.push({
              operation: elem.operation.tag,
              unit: elem.unit.name,
              value: elem.value
            });
          }
          let bodyDB = {
            name: this.newNotiName,
            description: this.newNotiDescrip,
            condition: {
              type: this.newNotiType.tag,
              children: childPost
            }
          };
          let resultCollection = await axios.post(this.urlCo2 + config.urlOrganizations + this.userLocal.id + config.urlAlerts,
            bodyDB,
            {
              headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.userLocal.token}`
              }
            }
          )
          .then(authoritation => {
            this.waitLoadScreen = false;
            this.newNotiName = '';
            this.newNotiDescrip = '';
            this.newNotiType = '';
            this.arrayNewConditionAlerts = [];
            this.titleDialog = "NOTIFICACIÓN REFGISTRADA";
            this.textDialog = "Se ha dado de alta con éxito la notificación introducida.";
            this.showDialogSuccessful();
            this.init();
            return authoritation;
          })
          .catch(function(error){
            console.error(error);
            return undefined;
          });
          if(!resultCollection){
            this.waitLoadScreen = false;
            this.valueMutable = true;
            this.titleDialog = "ERROR INESPERADO";
            this.textDialog = "Ha surgido un error inesperado en la colección de notificaciones al dar de alta una nueva notificación";
            this.showDialogErrorGeneral();
          }       
        }
      },
      // OPEN DIALOGS
      async showDialogErrorGeneral() {
        try {
          await this.$refs.dialogError.open(
            this.titleDialog,
            this.textDialog
          )
        } catch (error) {
          console.error(error);
        }
      },
      async showDialogSuccessful() {
        try {
          await this.$refs.dialogSuccessful.open(
            this.titleDialog,
            this.textDialog
          )
        } catch (error) {
          console.error(error);
        }
      },
      async removeAlert(item){
        try {
          await this.$refs.dialogRemoveAlert.open(
            item,
            "ELIMNAR NOTIFICACIÓN",
            "¿Está seguro de eliminar la notificación: "+item.id+"?"
          )
        } catch (error) {
          console.error(error);
        }
      },
    }
  }
</script>