<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800" style="overflow-x: hidden">
      <v-row class="ma-0 pa-0 dialogTitleClass bg-dialog-info">
        <v-col class="text-center">
          <img class="mr-4" width="40" height="40" src="../assets/svg/ic_calibrate_white.svg">CALIBRAR DISPOSITIVO: {{ deviceId }} 
          <div class="text-dialog-input-location">Elegir la opción de calibrar ahora o programar un día de calibrado</div>
        </v-col>
      </v-row>
      
      <v-row class="ma-0 pa-0 mt-5 d-flex justify-center">
        <v-col cols="11" class="ma-0 pa-0 text-center">
          <div class="my-2 panel-calibrate" :style="expandNowCalibrate" @click="changeOption(1)" @mouseover="mouseOverDiv(1)">
            CALIBRAR AHORA
          </div>
          <div class="my-2 panel-calibrate" :style="expandProgramCalibrate" @click="changeOption(2)" @mouseover="mouseOverDiv(2)">
            <div v-if="!showOptionsProgramCalibrate">PROGRAMAR CALIBRACIÓN</div>
            <div v-else>
              <v-row>
                <v-col>
                  <div>PROGRAMAR CALIBRACIÓN PARA: {{ formatShowDate }}</div>
                  <div class="text-error-msg" v-if="flagError">{{textError}}</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-date-picker
                    v-model="pickerDate"
                    color="#00627c"
                    flat
                    locale="es"
                    @change="flagDisable=false;flagError=false"
                  ></v-date-picker>
                </v-col>
                <v-col>
                  <v-time-picker
                    v-model="pickerTime"
                    color="#00627c"
                    scrollable
                    format="24hr"
                    @change="flagDisable=false;flagError=false"
                  ></v-time-picker>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-slider
                    v-model="valueCalibrate"
                    color="#00627c"
                    label="Intervalo de días"
                    step="1"
                    min="1"
                    max="7"
                    thumb-label
                    ticks
                  >
                    <template v-slot:prepend>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="#00627c" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span class="tooltip-slider">Introducir un número de días del tiempo que debe estar funcionando de forma programada</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        v-model="valueCalibrate"
                        class="mt-0 pt-0"
                        color="#e2f4f5"
                        hide-details
                        single-line
                        type="number"
                        style="width: 40px"
                      ></v-text-field>
                    </template>
                  </v-slider>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
      
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0 py-4 px-4">
          <v-btn class="btn-back" @click="cancelCalibrate">CANCELAR</v-btn>
        </v-col>
        <v-col class="ma-0 pa-0 py-4 px-4">
          <v-btn class="btn-accept" @click="confirmCalibrate" :disabled="flagDisable">CONFIRMAR</v-btn>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay> 
  </v-row>
</template>

<script>
export default {
  name: "dialogCalibrate",
  data(){
    return{
      userLocal: undefined,
      waitLoadScreen: false,
      textLoader: '',
      flagError: false,
      textDialog: '',
      expandNowCalibrate: '',
      expandProgramCalibrate: '',
      showOptionsProgramCalibrate: false,
      pickerDate: null,
      pickerTime: null,
      datePostProgram: '',
      valueCalibrate: 1,
      flagDisable: false,
    }
  },
  props:{
    value: Boolean,
    deviceId: String
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formatShowDate(){
      return this.saveShowDate();
    }
  },  
  mounted(){
    this.expandNowCalibrate = 'height:80px !important; background-color: #33abcc;';
    this.expandProgramCalibrate = 'height:80px !important; background-color: #9bcbd8;';
  },
  methods:{
    saveShowDate(){
      let formatFinal = '';
      if(this.pickerDate && this.pickerDate != null){
        let part = this.pickerDate.split("-");
        formatFinal = part[2] + "-" + part[1] + "-" + part[0];
      }
      if(this.pickerTime && this.pickerTime != null){
        formatFinal += " -> " + this.pickerTime + ":00";
      }
      this.datePostProgram = this.pickerDate + " " + this.pickerTime + ":00";
      return formatFinal;
    },
    mouseOverDiv(op){
      if(op == 1){
        if(this.showOptionsProgramCalibrate == false){
          this.expandNowCalibrate = 'height:80px !important; background-color: #29c2ca;';
          this.expandProgramCalibrate = 'height:80px !important; background-color: #9bcbd8;';
        }
        else{
          this.expandNowCalibrate = 'height:80px !important; background-color: #29c2ca;';
          this.expandProgramCalibrate = 'height:540px !important; background-color: #33abcc;';
        }
      }
      else if(op == 2){
        if(this.showOptionsProgramCalibrate == false){
          this.expandNowCalibrate = 'height:80px !important; background-color: #33abcc;';
          this.expandProgramCalibrate = 'height:80px !important; background-color: #29c2ca;';
        }
        else{
          this.expandNowCalibrate = 'height:80px !important; background-color: #9bcbd8;';
          this.expandProgramCalibrate = 'height:540px !important; background-color: #33abcc;';
        }
      }
    },
    changeOption(op){
      if(op == 1){
        this.expandNowCalibrate = 'height:80px !important; background-color: #33abcc;';
        this.expandProgramCalibrate = 'height:80px !important; background-color: #9bcbd8;';
        this.showOptionsProgramCalibrate = false;
      }
      else if(op == 2){
        this.expandNowCalibrate = 'height:80px !important; background-color: #9bcbd8;';
        this.expandProgramCalibrate = 'height:540px !important; background-color: #33abcc;';
        this.showOptionsProgramCalibrate = true;
      }
    },
    confirmCalibrate(){
      if(this.showOptionsProgramCalibrate == false){
          this.$emit('refreshinfo', {active: true, id: this.deviceId, date: this.datePostProgram, interval: this.valueCalibrate, type: 'calibrateNow' });
          this.refresh();
          this.flagDisable = false;
          this.flagError = false;
          this.dialog = false;
      }
      else{
        if(this.pickerDate != null && this.pickerTime != null){
          if(this.valueCalibrate != undefined && this.valueCalibrate != ''){
            this.$emit('refreshinfo', {active: true, id: this.deviceId, date: this.datePostProgram, interval: this.valueCalibrate, type: 'programCalibration' });
            this.refresh();
            this.flagDisable = false;
            this.flagError = false;
            this.dialog = false;
          }
          else{
            this.flagDisable = true;
            this.flagError = true;
            this.textError = "Se debe introducir un intervalo de días";
          }
        }
        else{
          this.flagDisable = true;
          this.flagError = true;
          this.textError = "Se debe introducir tanto la fecha como la hora para la programación del calibrado.";
        }
      }
    },
    cancelCalibrate(){
      this.$emit('refreshinfo', {active: false });
      this.refresh();
      this.dialog = false;
    },
    refresh(){
      this.expandNowCalibrate = 'height:80px !important; background-color: #33abcc;';
      this.expandProgramCalibrate = 'height:80px !important; background-color: #9bcbd8;';
      this.showOptionsProgramCalibrate = false;
      this.pickerDate = null;
      this.pickerTime = null;
      this.datePostProgram = '';
      this.valueCalibrate = 1;
      this.flagDisable = false;
    }
  }
}
</script>