<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800" style="overflow-x: hidden">
      <v-row class="ma-0 pa-0 dialogTitleClass bg-dialog-info">
        <v-col class="d-flex justify-center align-center">
          <img class="mr-4" width="40" height="40" src="../assets/svg/ic_email_white.svg">DAR DE ALTA EMAIL
        </v-col>
      </v-row>
      <v-form id="idFormNewEmail" ref="formNewEmail" v-model="validNewEmail" lazy-validation v-on:submit.prevent="noop">
        <v-row class="ma-0 pa-0 mt-5 d-flex justify-center">
          <v-col cols="11" class="ma-0 pa-0 text-center">
            <div v-if="!mailOK" class="font-co2 font-size-co2-8">{{ textError }}</div>
            <v-text-field
              id="idNewEmail"
              class="text-input-white"
              type="text"
              v-model="newEmail"
              color="#094353"
              label="Introducir dirección de correo electrónico"
              placeholder="Ej: miCorreo@gmail.com"
              :rules="[rules.required, rules.email]"
              required
            >
              <template v-slot:prepend>
                <img width="24" height="24" src="../assets/svg/ic_email_color.svg">
              </template>
            </v-text-field> 
          </v-col>
        </v-row>
        
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0 py-4 px-4">
            <v-btn class="btn-back" @click="cancel">CANCELAR</v-btn>
          </v-col>
          <v-col class="ma-0 pa-0 py-4 px-4">
            <v-btn class="btn-accept" @click="registerEmail" :disabled="flagDisable">REGISTRAR</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-dialog>
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay> 
  </v-row>
</template>

<script>
export default {
  name: "dialogNewEamil",
  data(){
    return{
      userLocal: undefined,
      waitLoadScreen: false,
      textLoader: '',
      flagError: false,
      textDialog: '',
      validNewEmail: true,
      flagDisable: false,
      textError: "",
      mailOK: true,
      newEmail: undefined,
      rules: {
        required: value => !!value || 'Campo obligatorio',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  props:{
    value: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods:{
    registerEmail(){
      if(this.$refs.formNewEmail.validate()){
        this.$emit('postRegisterEmail', {email: this.newEmail});
        this.mailOK = true;
        this.newEmail = undefined;
        this.$refs.formNewEmail.reset();
        this.dialog = false;        
      }
      else{
        this.mailOK = false;
        this.textError = "Debes introducir una dirección de correo válida"
      }
    },
    cancel(){
      this.newEmail = undefined;
      this.dialog = false;
    }
  }
}
</script>