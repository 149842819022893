<template>
  <div class="mt-8">
    <!-- HEADER -->
    <v-row class="primaryCo2 pt-4">
      <v-col class="d-flex align-center ml-10">
        <img width="40" height="40" src="../assets/svg/ic_device_white.svg">
        <span class="font-co2 font-size-co2-5 ml-5">ADMINISTRAR DISPOSITIVOS</span>
      </v-col>
    </v-row>
    <!-- CONTENT -->
    <v-row class="d-flex justify-center">
      <!-- Select organization -->
      <v-col cols="4" v-if="rol == 'admin'">
        <v-autocomplete
          id="idSelectOrganization"
          v-model="selectOrganization"
          :items="getDataOrganizations"
          class="px-12 pt-6"
          color="#00627c"
          label="Seleccionar una organización"
          placeholder="Ej: Global Textil S.A."
          item-text="name"
          return-object
          @change="loadInfoOrganization"
        >
          <template v-slot:prepend>
            <img width="24" height="24" src="../assets/svg/ic_location_company_color.svg">
          </template>
        </v-autocomplete>
      </v-col>
      <!-- Show info organization devices-->
      <v-col cols="8">
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="table-header-devices"></th>
                <th class="table-header-devices text-left">ID</th>
                <th class="table-header-devices text-left">Nombre del dispositivo</th>
                <th class="table-header-devices text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in arrayDevicesOrganizations" :key="item.id">
                <td>
                  <img v-if="item.id===selectedId" width="24" height="24" src="../assets/svg/ic_device_location_marker_select.svg">
                  <img v-else width="24" height="24" src="../assets/svg/ic_device_location_marker.svg">
                </td>
                <td class="font-co2 font-size-co2-3">{{ item.id }}</td>
                <td class="font-co2 font-size-co2-3">{{ item.name }}</td>
                <td>
                  <v-btn elevation="0" class="btn-red font-co2" v-on:click="removeDevice(item)">
                    <div class="pos-icon-btn-left"><img width="24" height="24" src="../assets/svg/ic_update_down_white.svg"></div>
                    DAR DE BAJA
                  </v-btn> 
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <!-- DIALOG -->
    <DialogRemoveDevice ref="dialogRemoveDevice" @respondRemoveDevice="RefreshRemoveDevice" />  
    <DialogError ref="dialogError" />
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay> 
  </div>
</template>

<script>
  import config from '../config.json';
  import axios from "axios";

  export default {
    name: 'deviceManager',
    data () {
      return {
        urlCo2Organizations: config.host + config.apiUrl + config.urlOrganizations,
        urlCo2Users: config.host + config.apiUrl + config.urlUsers,
        urlCo2Devices: config.host + config.apiUrl + config.urlDevices,
        waitLoadScreen: false,
        textLoader: '',
        selectOrganization: '',
        arrayDevicesOrganizations: [],
        selectedId: -1,
        userLocal: undefined,
        getDataOrganizations: [],
        getDataDevices: [],
        getDataUsers: [],
        titleError: '',
        textError: '',
        rol: ''
      }
    },
    components: {
      DialogRemoveDevice: () => import("../components/DialogRemoveDevice"),
      DialogError: () => import("../components/DialogError"),
    },
    mounted(){
      this.initialize();
    },
    methods:{
      async initialize(){
        this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
        this.rol = this.userLocal.rol;
        
        if(this.rol == 'admin'){
          this.waitLoadScreen = true;
          this.textLoader = "Cargando...";
          this.loadAllOrganizations();
          this.loadAllDevices();
          this.loadAllUsers();
          this.waitLoadScreen = false;
        }
        else if(this.rol == 'organization'){
          this.waitLoadScreen = true;
          this.textLoader = "Cargando...";
          this.loadAllDevicesOrganizations();
          this.waitLoadScreen = false;
        }
        else{
          // USER MOD
          this.waitLoadScreen = true;
          this.textLoader = "Cargando...";
          this.loadAllDevicesUser();
          this.waitLoadScreen = false;
        }
      },
      async loadAllOrganizations(){
        let collectOrganizations = await axios.get(this.urlCo2Organizations,
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
          this.getDataOrganizations = authoritation.data;
          this.getDataOrganizations.unshift({ _id:'0', name: 'VER TODOS', id:'0' });  
          return authoritation;
        })
        .catch(function(error){
          console.error(error);
          return undefined;
        });
        if(!collectOrganizations){
          this.waitLoadScreen = false;
          this.titleError = "ERROR INESPERADO";
          this.textError = "Ha surgido un error inesperado en la colección: organizations";
          this.showDialogErrorGeneral();
        }
      },
      async loadAllDevices(){
        let collectDevices = await axios.get(this.urlCo2Devices,         
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
            this.getDataDevices = authoritation.data;
            this.devicesWithLocation(this.getDataDevices)
            return authoritation;
        })
        .catch(function(error){
            console.error(error);
            return undefined;
        });
        if(!collectDevices){
          this.waitLoadScreen = false;
          this.titleError = "ERROR INESPERADO";
          this.textError = "Ha surgido un error inesperado en la colección: devices";
          this.showDialogErrorGeneral();
        }
      },
      async loadAllDevicesOrganizations(){
        let flagError = false;
        await axios.get(this.urlCo2Devices,         
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
            let ArrayAUX = [];
            for (const elem of authoritation.data) {
              ArrayAUX.push(elem);
            }
            this.getDataDevicesUser = ArrayAUX;
            this.arrayDevicesOrganizations = this.getDataDevicesUser;
            this.selectOrganization = 'organizationList';
        })
        .catch(function(error){
            console.error(error);
            flagError = true;
            return undefined;
        });
        if(flagError){
          this.waitLoadScreen = false;
          this.valueMutable = true;
          this.titleError = "ERROR INESPERADO";
          this.textError = "Ha surgido un error inesperado en la colección de la organización: devices";
          this.showDialogErrorGeneral();
        }
      },      
      devicesWithLocation(list){
        if(list != undefined && list.length > 0){
          let arrayAUX = [];
          for (const elem of list) {
            if(elem.position != undefined && elem.position.coordinates != undefined && elem.position.coordinates.length != 0){
              arrayAUX.push(elem);
            }
          }
          this.getDataDevices = arrayAUX;          
        }
        else{
         console.warn("WARM: Empty devices list!");
        }
      },
      async loadAllDevicesUser(){
        let collectDevicesUser = await axios.get(this.urlCo2Devices,         
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
            this.getDataDevicesUser = authoritation.data;
            this.arrayDevicesOrganizations = this.getDataDevicesUser;
            this.selectOrganization = 'userList';
            return authoritation;
        })
        .catch(function(error){
            console.error(error);
            return undefined;
        });
        if(!collectDevicesUser){
          this.waitLoadScreen = false;
          this.valueMutable = true;
          this.titleError = "ERROR INESPERADO";
          this.textError = "Ha surgido un error inesperado en la colección de usuario: devices";
          this.showDialogErrorGeneral();
        }
      },      
      async loadAllUsers(){
        let collectUsers = await axios.get(this.urlCo2Users,         
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
            this.getDataUsers = authoritation.data;
            return authoritation;
        })
        .catch(function(error){
            console.error(error);
            return undefined;
        });
        if(!collectUsers){
          this.waitLoadScreen = false;
          this.titleError = "ERROR INESPERADO";
          this.textError = "Ha surgido un error inesperado en la colección: users";
          this.showDialogErrorGeneral();          
        }
      },
      loadInfoOrganization(){
        this.waitLoadScreen = true;
        this.textLoader = 'Buscando dispositivos...';
        if(this.selectOrganization.name == 'VER TODOS'){
          this.arrayDevicesOrganizations = this.getDataDevices;
        }
        else{
          let arrayAUXUsers = [];
          this.getDataUsers.forEach(elemUser => {
            if(elemUser.organizationId == this.selectOrganization.id){
              arrayAUXUsers.push(elemUser);
            }
          });
          this.arrayDevicesOrganizations = [];
          arrayAUXUsers.forEach(elemAuxUsers => {
            this.getDataDevices.forEach(elemDev => {
              if(elemDev.userId == elemAuxUsers.id){
                this.arrayDevicesOrganizations.push(elemDev);
              }
            });          
          });
        }
        this.waitLoadScreen = false;
      },
      RefreshRemoveDevice(e){
        if(this.rol == 'admin'){
          let arrayAUX = [];
          this.getDataDevices.forEach(elem => {
            if(elem.id != e.id){
              arrayAUX.push(elem);
            }
          }); 
          this.getDataDevices = arrayAUX;
          this.loadInfoOrganization();
        }
        else{
          let arrayAUX = [];
          this.getDataDevicesUser.forEach(elem => {
            if(elem.id != e.id){
              arrayAUX.push(elem);
            }
          }); 
          this.getDataDevicesUser = arrayAUX;
          this.arrayDevicesOrganizations = this.getDataDevicesUser;
        }
      },
      // OPEN DIALOGS
      async showDialogErrorGeneral() {
        try {
          await this.$refs.dialogError.open(
            this.titleError,
            this.textError
          )
        } catch (error) {
          console.error(error);
        }
      },
      async removeDevice(item){
        try {
          await this.$refs.dialogRemoveDevice.open(
            item,
            "DAR DE BAJA",
            "¿Está seguro de dar de baja el dispositivo: "+item.name+"?"
          )
        } catch (error) {
          console.error(error);
        }
      },
    }
  }
</script>