<template>
  <div id="idViewLogin">
    <v-app>
      <v-main>
        <!-- LOGIN FORM -->
        <v-row class="ma-0 pa-0 bg-main url-login-co2 d-none d-sm-none d-md-flex justify-end">
          <v-col cols="12" sm="12" md="8" lg="7" xl="7" class="ma-0 pa-0">
            <transition 
              enter-active-class="animate__animated animate__slideInRight"
              mode="out-in"
            >
              <login-form v-if="openLogin" @respondFormLogin="respondLogin" />
              <register-form v-else :tokenRegister="tokenRegister" @respondRegisterLogin="respondRegister" />
            </transition>
          </v-col>
        </v-row>
        <!-- Form small responsive --> 
        <v-row class="ma-0 pa-0 d-flex d-sm-flex d-md-none">
          <v-col cols="12" sm="12" md="8" lg="7" xl="7" class="ma-0 pa-0">
            <login-form v-if="openLogin" @respondFormLogin="respondLogin" />
            <register-form v-else :tokenRegister="tokenRegister" @respondRegisterLogin="respondRegister" />    
          </v-col>
        </v-row>      
      </v-main>
    </v-app>
    <!-- DIALOG -->
    <DialogSuccessful ref="dialogSuccessful" /> 
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
import RegisterForm from '../components/RegisterForm.vue';
export default {
  name: 'loginCo2',
  data(){
    return{
      openLogin: true,
      respondNameUser: '',
      tokenRegister: '',
    }
  },
  components: {
    DialogSuccessful: () => import("../components/DialogSuccessful"),
    LoginForm,
    RegisterForm,
  },
  created(){
    this.linkRegisterExist(); // ?idOrg=
  },
  methods:{
    linkRegisterExist(){
      let urlPart = window.location.href.split("?idOrg=");
      if(urlPart[1] != undefined){
        this.tokenRegister = urlPart[1];
        this.openLogin = false;
      }
    },
    respondLogin(e){
      this.openLogin = e.value;
    },
    respondRegister(e){
      this.openLogin = e.value;
      if(e.type == 'register'){
        this.respondNameUser = e.nameUser;
        this.showDialogSuccessful();        
      }
    },
    async showDialogSuccessful() {
      try {
        await this.$refs.dialogSuccessful.open(
          "USUARIO REGISTRADO",
          "El usuario " + this.respondNameUser + " se ha registrado correctamente."
        )
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>