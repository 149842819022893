<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000" style="overflow-x: hidden">
      <v-card-title class="dialogTitleClass bg-dialog-info">
        <img class="mr-4" width="40" height="40" src="../assets/svg/ic_device_white.svg">DISPOSITIVO DESCONOCIDO: {{ deviceUnknownRead }} 
        <div class="text-dialog-input-location">Se necesita introducir la localización de este dispositivo para su correcta gestión</div>
      </v-card-title>
      <v-row class="ma-0 pa-0 mt-3">
        <v-col class="ma-0 pa-0">
          <v-text-field
            id="idNameDevice"
            v-model="nameDevice"
            class="mx-8"
            color="#00627c"
            label="Introducir nombre del dispositivo"
            placeholder="Ej: Dispositivo 1"
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_device_company_color.svg">
            </template>
          </v-text-field>
          <v-text-field
            id="idNameAddress"
            v-model="nameAddress"
            class="mx-8"
            color="#00627c"
            label="Introducir dirección postal ó seleccionar en el mapa"
            placeholder="Ej: C/ Gran Vía"
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_device_location_color.svg">
            </template>
          </v-text-field>
          <div v-if="notLocation" class="classNotLocation">{{ textNotLocation }}</div>
          <v-select v-if="showLocations == true"
            :items="itemsAddress"
            v-model="selectAddress"
            class="mx-8"
            color="#00627c"
            label="Seleccionar una localización de búsqueda"
            @change="refreshLocationMarker"
            item-text="name"
            return-object
            :loading="loaderSelect"
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_location_company_color.svg">
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center ml-4">
          <l-map
            ref="map"
            v-if="showMap"
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            style="height: 400px; width:950px"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
            v-resize="onResize"
            @click="addMarker"
          >
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-marker v-if="flagMarker" :lat-lng="markerPlace" :draggable="true">
              <l-icon :icon-url="iconMarkerDeviceSelect"></l-icon>
            </l-marker>
          </l-map>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0 py-4 px-4">
          <v-btn class="btn-accept" @click="confirmPosition">CONFIRMAR</v-btn>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay> 
  </v-row>
</template>

<script>
import config from '../config.json';
import axios from "axios";
import { latLng} from "leaflet"
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import iconMarkerDeviceSelect from '../assets/svg/ic_device_location_marker_select.svg';
export default {
  data: () => ({
    urlCo2Devices: config.host + config.apiUrl + config.urlDevices,
    urlNominatim: config.urlNominatimName,
    UrlGetAddress: config.urlNominatimCordinates,
    userLocal: undefined,
    waitLoadScreen: false,
    textLoader: '',
    titleDialog: '',
    textDialog: '',
    zoom: 14,
    center: [40.965015705732085, -5.664040642828293],
    nameDevice: '',
    nameAddress: '',
    notLocation: false,
    textNotLocation: '',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    markerPlace: latLng(40.965157, -5.6640182),
    currentZoom: 11.5,
    currentCenter: latLng(40.965157, -5.6640182),
    mapOptions: {
      zoomSnap: 0.5
    },
    showMap: true,
    iconMarkerDeviceSelect,
    codeIdDev: '?',
    itemsAddress: [],
    selectAddress: '',
    flagMarker: true,
  }),
  props: {
    value: Boolean,
    deviceUnknown: {}
  },
  components:{
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },  
    deviceUnknownRead: function(){
      return this.resIdDiv();
    },
    showLocations: function(){
      if(this.nameAddress != ''){
        if(this.notLocation == true){
          return false
        }
        return true;
      }
      return false;
    }
  },
  watch: {
    nameAddress(){
      this.searchListLocations();
    }
  },
  mounted(){
    this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
  },
  methods: {
    async searchListLocations(){
      this.flagMarker = false;
      this.loaderSelect = true;
      let resultSearch = undefined;
      let resultColletion = await axios.get(this.urlNominatim + this.nameAddress,
        {
          headers:{
            "Content-Type": "application/json"
          }
        }
      )
      .then(authoritation => {
        resultSearch = authoritation.data;
        return authoritation;
      })
      .catch(function(){
        //console.error(error); error
        return undefined;
      });
      if(resultColletion){
        this.itemsAddress = [];
        for (const item of resultSearch.features) {
          this.itemsAddress.push({name: item.properties.display_name, coordinates: [item.geometry.coordinates[1], item.geometry.coordinates[0]]})
        }
      }
      if(this.nameAddress == ''){
        this.markerPlace = latLng(40.965157, -5.6640182);
        this.center = latLng(40.965157, -5.6640182);
        this.flagMarker = true;
        this.notLocation = false;
      }
      else if(this.itemsAddress.length == 0){
        this.flagMarker = false;
        this.textNotLocation = 'No hay coincidencias. Especificar mejor la dirección';
        this.notLocation = true;
      }
      else{
        this.flagMarker = true;
        this.notLocation = false;
      }
      this.loaderSelect = false;
    },
    refreshLocationMarker(){
      this.markerPlace = latLng(this.selectAddress.coordinates[0], this.selectAddress.coordinates[1]);
      this.center = this.selectAddress.coordinates;
      this.flagMarker = true;
    },
    resIdDiv(){
      if(this.deviceUnknown != undefined){
        this.codeIdDev = this.deviceUnknown.id;
        return this.codeIdDev;
      }
      else{
        return '?'
      }
    },
    addMarker(event) {
      this.zoomUpdate(13);
      this.markerPlace = latLng(event.latlng.lat, event.latlng.lng);
      this.zoomUpdate(this.zoom);
      this.nameAddress = '';
      this.selectAddress = '';
      this.notLocation = false;
      this.getNameAddress(event.latlng.lat, event.latlng.lng);
    },
    async getNameAddress(lat, long){
      this.collectOrganizations = axios.get(this.UrlGetAddress + lat + "&lon="+ long + "&format=json",
        {
          headers:{
            "Content-Type": "application/json",
          }
        }
      )
      .then(authoritation => {
        this.nameAddress = authoritation.data.address.road + ", " + authoritation.data.address.city_district + ", " + authoritation.data.address.city + ", " + authoritation.data.address.state + ", " + authoritation.data.address.postcode + ", " + authoritation.data.address.country;
        this.selectAddress = { name: this.nameAddress, coordinates: [ lat, long ] }
        this.flagMarker = true;
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        return undefined;
      });
    },    
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    onResize() {
      this.$refs.map.mapObject._onResize();
    },
    async confirmPosition(){
      if(this.nameDevice != ''){
        if(this.markerPlace.lat != null && this.markerPlace.lng != null){
          this.waitLoadScreen = true;
          this.textLoader = "Guardando usuario...";
          let deviceMod = this.deviceUnknown;
          deviceMod.position.coordinates = [this.markerPlace.lat, this.markerPlace.lng];
          let bodyDB = {
            mac: deviceMod.mac,
            position: deviceMod.position,
            name: this.nameDevice
          }
          let result = await axios.put(this.urlCo2Devices + deviceMod.id,
            bodyDB,
            {
              headers:{ 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.userLocal.token}` 
              },
            }
          )
          .then(authoritation => {
            return authoritation;
          })
          .catch(function(error){
            console.error(error);
            return undefined;
          });
          if(result){
            this.$emit('assignDeviceOk');
            this.waitLoadScreen = false;
            this.dialog = false
          }
          else{
            this.waitLoadScreen = false;
            this.titleDialog = 'ERROR EN EL DISPOSITIVO'
            this.textDialog = 'No se ha podido registrar la localización del nuevo dispositivo';
            this.showDialogErrorGeneral();
          }
          this.notLocation = false;
        }
        else{
          this.textNotLocation = 'Error al marcar la posición. Reasignar de nuevo posición o intentar completar mejor los datos.';
          this.notLocation = true;
          console.error("Error en la posición del marker: ", [this.markerPlace.lat, this.markerPlace.lng]);
        }
      }
      else{
        this.textNotLocation = 'Error. Se debe introducir el nombre del dispositivo';
        this.notLocation = true;
      }

    },
    // DIALOG
    async showDialogErrorGeneral() {
      try {
        await this.$refs.dialogError.open(
          this.titleDialog,
          this.textDialog
        )
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>