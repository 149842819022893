<template>
  <div>
    <v-form id="idFormLogin" ref="formLogin" v-model="validLogin" lazy-validation v-on:submit.prevent="noop">

      <v-row class="ma-0 pa-0 mt-8 justify-end">
        <v-col cols="12" sm="12" md="7" lg="7" xl="7" class="ma-0 pa-0">
          <v-row>
            <v-col class="mt-2 mb-10">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0"><v-img src="../assets/img/banner-air-CO2.png" aspect-ratio="5" contain></v-img></v-col>
                <v-col class="ma-0 pa-0"><v-img src="../assets/img/logo_ayuntamiento_salamanca.png" aspect-ratio="5" contain></v-img></v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="bg-co2-title font-co2 font-size-co2-1 text-center">ACCEDER A LA CUENTA DE USUARIO</div>
          <v-text-field
            @keyup.enter="loginAccess"
            id="idEmailUserRes"
            v-model="nameUser"
            class="px-12 pt-6"
            color="#00627c"
            label="Introducir nombre de usuario"
            placeholder="Ej: My user"
            :rules="[rules.required]"
            required
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_user_color.svg">
            </template>
          </v-text-field>
          <v-text-field
            @keyup.enter="loginAccess"
            id="idPasswUser"
            v-model="passwUser"
            class="px-12 pt-6"
            color="#00627c"
            label="Introducir contraseña"
            placeholder="Ej: J1234_xdf"
            :append-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassw ? 'text' : 'password'"
            @click:append="showPassw = !showPassw"
            :rules="[rules.required]"
            required
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_password_color.svg">
            </template>
          </v-text-field>
          <div class="text-center mt-6"><a id="linkResetPassw" class="link-co2" href="#" @click="sendForgotPassw">¿Ha olvidado la contraseña?</a></div>
          <v-row class="d-flex justify-center mt-6">
            <v-col cols="9" sm="9" md="8" lg="9" xl="9">
              <v-btn elevation="0" class="btn-normal font-co2" @click="loginAccess">ACCEDER</v-btn>                      
            </v-col>
          </v-row>
          <div class="dividerClass"></div>
          <v-row class="d-flex justify-center">
            <v-col cols="9" sm="9" md="8" lg="9" xl="9">
              <div class="text-center font-co2 font-size-co2-2 mb-4">Si es la primera debe darse de alta como usuario</div>
              <v-btn elevation="0" class="btn-normal font-co2" v-on:click="goRegisterForm">DARSE DE ALTA</v-btn>                      
            </v-col>
          </v-row>
          <div class="dividerClass"></div>
          <v-row class="d-flex justify-center">
            <v-col cols="9" sm="9" md="6" lg="9" xl="9">
              <div class="text-center"><a id="linkResetPassw" class="link-co2" href="#" @click="downloadDocWeb">
                <div class="d-inline mr-4"><img width="40" height="40" src="../assets/svg/ic_doc_web_blue.svg"></div>DESCARGAR MANUAL WEB</a></div>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="9" sm="9" md="6" lg="9" xl="9">
              <div class="text-center"><a id="linkResetPassw" class="link-co2" href="#" @click="downloadDocDevice">
                <div class="d-inline mr-2"><img width="40" height="40" src="../assets/svg/ic_doc_device_blue.svg"></div>DESCARGAR MANUAL DEL DISPOSITIVO</a></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>   
    <!-- DIALOG -->
    <DialogError ref="dialogError" /> 
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay>      
  </div>
</template>

<script>
import config from '../config.json';
import axios from "axios";
export default {
  name: 'loginForm',
  data(){
    return{
      urlCo2Login: config.host + config.apiUrl + config.urlLogin,
      urlDeepEnvDocUser: config.host + config.apiUrl + config.urlDocumentsUser,
      urlDeepEnvDocDevice: config.host + config.apiUrl + config.urlDocumentsDevice,
      waitLoadScreen: false,
      textLoader: '',
      titleError: '',
      textError: '',
      validLogin: true,
      nameUser: '',
      passwUser: '',
      showPassw: false,
      rules: {
        required: value => !!value || 'Campo obligatorio',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  components: {
    DialogError: () => import("../components/DialogError"),
  },
  methods:{
    async loginAccess(){
      
      if(this.$refs.formLogin.validate()){

        this.authoritation = await axios.post(this.urlCo2Login,
          {
            name: this.nameUser,
            password: this.passwUser,
          },
          {
            headers:{
              "Content-Type": "application/json"
            }
          }
        )
        .then(authoritation => {
          window.localStorage.setItem("infoUser",JSON.stringify({ id: authoritation.data.id, token: authoritation.data.token, rol: authoritation.data.role }));
          return authoritation;
        })
        .catch(function(error){
          console.error(error);
          return undefined;
        }); 

        if(this.authoritation){
          this.waitLoadScreen = false;
          this.$router.push({
            path: '/home',
          });
        }
        else{
          this.waitLoadScreen = false;
          this.titleError = 'ACCESO DENEGADO';
          this.textError = 'La dirección de correo o la contraseña no son correctas';
          this.showDialogUserDenied();
        }
      }
    },
    sendForgotPassw(){
      console.log("Mandar solicitud para recuperar contraseña");
    },
    goRegisterForm(){
      this.$emit("respondFormLogin", { value: false });
    },
    noop(){},
    async downloadDocWeb(){
      this.waitLoadScreen = true;
      this.textLoader = "Descargando...";
      window.open(this.urlDeepEnvDocUser, "_blank")
      this.waitLoadScreen = false;
    },
    async downloadDocDevice(){
      this.waitLoadScreen = true;
      this.textLoader = "Descargando...";
      window.open(this.urlDeepEnvDocDevice, "_blank")
      this.waitLoadScreen = false;
    },
    // OPEN DIALOGS
    async showDialogUserDenied() {
      try {
        await this.$refs.dialogError.open(
          this.titleError,
          this.textError
        )
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>