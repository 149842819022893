<template>
    <div>
      <!-- MAP -->
      <v-row class="mt-6 mx-6">
        <v-col>
          <div v-if="JSON.parse(this.valueMutable) == false" ref="mapa">
            <l-map
              ref="myMap"
              :zoom="zoom"
              :center="center"
              style="height: 450px; width: 100%"
            >
              <l-tile-layer :url="urlLeaflet" />

              <!-- MARKERS - LLAVES DE SECTOR -->
              <l-marker 
                v-for="mark in arrayDevicesOrganizations" :key="mark.id"
                :lat-lng="mark.position.coordinates"
                @click="showInfoDeviceSelect(mark)"
              > 
                <l-icon v-if="mark.id===selectedId" :icon-url="iconMarkerDeviceSelect"></l-icon>
                <l-icon v-else :icon-url="iconMarkerDevice"></l-icon>
                <l-tooltip>{{mark.name}}</l-tooltip>
              </l-marker>
            </l-map>
          </div>
        </v-col>
      </v-row>   

      <!-- INFO ORGANIZATION -->
      <v-row>
        <!-- List organization -->
        <v-col>
          <v-autocomplete
            v-if="rol == 'admin'"
            id="idSelectOrganization"
            v-model="selectOrganization"
            :items="getDataOrganizations"
            class="px-12 pt-6"
            color="#00627c"
            label="Seleccionar una organización"
            placeholder="Ej: Global Textil S.A."
            item-text="name"
            return-object
            @change="loadInfoDeviceSelect"
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_location_company_color.svg">
            </template>
          </v-autocomplete>
          <!-- List devices -->
          <v-row v-if="selectOrganization != ''">
            <v-col>
              <v-card elevation="0" class="mx-auto px-8 mt-2">
                <v-simple-table fixed-header :height="heightTableDevice">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="table-header-devices"></th>
                        <th class="table-header-devices text-left">ID</th>
                        <th class="table-header-devices text-left">Nombre del dispositivo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in arrayDevicesOrganizations" :key="item.id" @click="showInfoDeviceSelect(item)" :class="{'selectCo2': item.id===selectedId}">
                        <td>
                          <img v-if="item.id===selectedId" width="24" height="24" src="../assets/svg/ic_device_location_marker_select.svg">
                          <img v-else width="24" height="24" src="../assets/svg/ic_device_location_marker.svg">
                        </td>
                        <td class="font-co2 font-size-co2-3">{{ item.id }}</td>
                        <td class="font-co2 font-size-co2-3">{{ item.name }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- Info device -->
        <v-col>
          <v-row v-if="valueId != ''">
            <v-col>
              <div class="bg-co2-home font-co2 font-size-co2-1 text-center">CONFIGURACIÓN DEL DISPOSITIVO</div>
              <v-row class="mx-12 mt-4">
                <v-col cols="1"><img width="40" height="40" src="../assets/svg/ic_device_id_color.svg"></v-col>
                <v-col class="my-auto"><div class="font-co2 font-size-co2-3">{{ this.valueId }}</div></v-col>
              </v-row>
              <v-row class="mx-12 mt-4">
                <v-col cols="1"><img width="40" height="40" src="../assets/svg/ic_device_company_color.svg"></v-col>
                <v-col class="my-auto"><div class="font-co2 font-size-co2-3">{{ this.valueNameDevice }}</div></v-col>
              </v-row>
              <v-row class="mx-12 mt-4">
                <v-col cols="1"><img width="40" height="40" src="../assets/svg/ic_device_location_color.svg"></v-col>
                <v-col class="my-auto"><div class="font-co2 font-size-co2-3">{{ this.valueAddress }}</div></v-col> 
              </v-row>
              <v-row class="mx-12 mt-4">
                <v-col>
                  <v-btn elevation="0" class="btn-normal font-co2" v-on:click="openDialogCalibrate">
                    <div class="pos-icon-btn-left"><img width="24" height="24" src="../assets/svg/ic_calibrate_white.svg"></div>
                    CALIBRAR
                  </v-btn> 
                </v-col>                
                <v-col>
                  <v-btn elevation="0" class="btn-normal font-co2" v-on:click="showStatsDevice">
                    <div class="pos-icon-btn-left"><img width="24" height="24" src="../assets/svg/ic_device_white.svg"></div>
                    VER DISPOSITIVO
                  </v-btn> 
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- DIALOG -->
      <DialogSuccessful ref="dialogSuccessful" @refreshshowMap="showMapDialog" /> 
      <DialogError ref="dialogError" @refreshshowMap="showMapDialog" />
      <dialog-input-location v-model="dialogPosition" :deviceUnknown="deviceUnknown" @assignDeviceOk="refreshDevice" />
      <dialog-calibrate v-model="openCalibrate" :deviceId="valueId" @refreshinfo="refreshinfo" />
      <!-- LOADER SCREEN -->
      <v-overlay :value="waitLoadScreen">
        <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
          <v-card class="pa-5" color="#00627c">
            <v-card-text>
              <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
              <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>                    
      </v-overlay> 
    </div>
</template>

<script>
  import config from '../config.json';
  import axios from "axios";
  import { LMap, LTileLayer, LMarker, LIcon, LTooltip } from "vue2-leaflet";
  import "leaflet/dist/leaflet.css"
  import iconMarkerDevice from '../assets/svg/ic_device_location_marker.svg';
  import iconMarkerDeviceSelect from '../assets/svg/ic_device_location_marker_select.svg';
  import DialogInputLocation from '../components/DialogInputLocation.vue';
  import DialogCalibrate from '../components/DialogCalibrate.vue';

  export default {
    name: 'contentHome',
    data () {
      return {
        urlCo2Organizations: config.host + config.apiUrl + config.urlOrganizations,
        urlCo2Users: config.host + config.apiUrl + config.urlUsers,
        urlCo2Devices: config.host + config.apiUrl + config.urlDevices,
        UrlGetAddress: config.urlNominatimCordinates,
        urlLeaflet: config.linkLeaflet,
        waitLoadScreen: false,
        collectOrganizations: [],
        collectDevices: [],
        collectDevicesUser: [],
        collectUsers: [],
        textLoader: '',
        iconMarkerDevice,
        iconMarkerDeviceSelect,
        zoom: 14,
        center: [40.965015705732085, -5.664040642828293],
        arrayOrganizations: [],
        selectOrganization: '',
        arrayDevicesOrganizations: [],
        selectedId: -1,
        valueId: '',
        valueNameDevice: '',
        valueAddress: '',
        getDataOrganizations: [],
        getDataDevices: [],
        getDataDevicesUser: [],
        getDataUsers: [],
        titleDialog: '',
        textDialog: '',
        userLocal: undefined,
        rol: "",
        dialogPosition: false,
        deviceUnknown: undefined,
        valueMutable: false,
        heightTableDevice: '250px',
        openCalibrate: false
      }
    },
    props: {
      value: Boolean,
    },
    components: {
      DialogSuccessful: () => import("../components/DialogSuccessful"),
      DialogError: () => import("../components/DialogError"),
      LMap,
      LTileLayer,
      LMarker,
      LIcon,
      LTooltip,
      DialogInputLocation,
      DialogCalibrate
    },
    mounted(){
      this.valueMutable = this.value;
      this.initialize();
      this.typeRolHeiht();
    },
    watch: {
      value: function(){
        if(this.value == true){
          this.valueMutable = true;
        }
        else{
          this.valueMutable = false;
        }
      }
    },
    methods:{
      async initialize(){
        this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
        this.rol = this.userLocal.rol;

        if(this.rol == 'admin'){
          this.waitLoadScreen = true;
          this.textLoader = "Cargando...";
          this.loadAllOrganizations();
          this.loadAllDevices();
          this.loadAllUsers();
          this.waitLoadScreen = false;
        }
        else if(this.rol == 'organization'){
          this.waitLoadScreen = true;
          this.textLoader = "Cargando...";
          this.loadAllDevicesOrganizations();
          this.waitLoadScreen = false;
        }
        else{
          // USER MOD
          this.waitLoadScreen = true;
          this.textLoader = "Cargando...";
          this.loadAllDevicesUser();
          this.waitLoadScreen = false;
        }
      },
      async loadAllOrganizations(){
        this.collectOrganizations = await axios.get(this.urlCo2Organizations,
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
          this.getDataOrganizations = authoritation.data;
          this.getDataOrganizations.unshift({ _id:'0', name: 'VER TODOS', id:'0' }); 
          return authoritation;
        })
        .catch(function(error){
          console.error(error);
          return undefined;
        });
        if(!this.collectOrganizations){
          this.waitLoadScreen = false;
          this.valueMutable = true;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado en la colección: organizations";
          this.showDialogErrorGeneral();
        }
      },
      async loadAllDevices(){
        this.collectDevices = await axios.get(this.urlCo2Devices,         
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
            this.getDataDevices = authoritation.data;
            this.devicesWithLocation(this.getDataDevices);
            return authoritation;
        })
        .catch(function(error){
            console.error(error);
            return undefined;
        });
        if(!this.collectDevices){
          this.waitLoadScreen = false;
          this.valueMutable = true;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado en la colección: devices";
          this.showDialogErrorGeneral();
        }
      },
      devicesWithLocation(list){
        if(list != undefined && list.length > 0){
          let arrayAUX = [];
          for (const elem of list) {
            if(elem.position != undefined && elem.position.coordinates != undefined && elem.position.coordinates.length != 0){
              arrayAUX.push(elem);
            }
          }
          if(this.rol == 'admin'){
            this.getDataDevices = arrayAUX;
            //this.checkDevicesPosition(this.getDataDevices);
          }
          else if(this.rol == 'organization'){
            this.arrayDevicesOrganizations = arrayAUX;
          }
        }
        else{
          console.warn("WARM: Empty devices list!");
        }
      },
      async loadAllDevicesUser(){
        this.collectDevicesUser = await axios.get(this.urlCo2Devices,         
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
            this.getDataDevicesUser = authoritation.data;
            this.arrayDevicesOrganizations = this.getDataDevicesUser;
            this.selectOrganization = 'userList';
            this.checkDevicesPosition(this.arrayDevicesOrganizations);
            return authoritation;
        })
        .catch(function(error){
            console.error(error);
            return undefined;
        });
        if(!this.collectDevicesUser){
          this.waitLoadScreen = false;
          this.valueMutable = true;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado en la colección de usuario: devices";
          this.showDialogErrorGeneral();
        }
      },
      async loadAllDevicesOrganizations(){
        this.collectDevicesUser = await axios.get(this.urlCo2Devices,         
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
            this.getDataDevicesUser = authoritation.data;
            this.arrayDevicesOrganizations = this.getDataDevicesUser;
            this.selectOrganization = 'organizationList';
            this.devicesWithLocation(this.arrayDevicesOrganizations);
            return authoritation;
        })
        .catch(function(error){
            console.error(error);
            this.waitLoadScreen = false;
            this.valueMutable = true;
            this.titleDialog = "ERROR INESPERADO";
            this.textDialog = "Ha surgido un error inesperado en la colección de la organización: devices";
            this.showDialogErrorGeneral();
            return undefined;
        }.bind(this));
      },
      async loadAllUsers(){
        this.collectUsers = await axios.get(this.urlCo2Users,         
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
            this.getDataUsers = authoritation.data;
            return authoritation;
        })
        .catch(function(error){
            console.error(error);
            return undefined;
        });
        if(!this.collectUsers){
          this.waitLoadScreen = false;
          this.valueMutable = true;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado en la colección: users";
          this.showDialogErrorGeneral();          
        }
      },
      checkDevicesPosition(listDevices){
        listDevices.forEach(async elem => {
          if(elem.position == undefined && elem.position.coordinates.length == 0 || elem.position.coordinates == null || elem.position.coordinates == ''){
            this.valueMutable = true;
            this.deviceUnknown = elem;
            this.dialogPosition = true;
          }
        });
      },
      refreshDevice(){
        this.waitLoadScreen = true;
        this.textLoader = "Cargando...";
        this.initialize();
        this.valueMutable = false;
        this.waitLoadScreen = false;
      },
      refreshinfo(e){
        if(e.active == true){
          this.saveCalibratePOST(e);
        }
        else{
          this.valueMutable = false;
        }
      },
      async saveCalibratePOST(data){
        this.waitLoadScreen = true;
        this.textLoader = "Guardando Calibrado...";
        let bodyDB = {};
        if(data.type == 'calibrateNow'){
          bodyDB = {
            calibrate: true
          }
        }
        else{
          bodyDB = {
            timestamp: data.date,
            interval: data.interval
          }
        }
        let resultCollection = await axios.post(this.urlCo2Devices + data.id + "/" + data.type,
          bodyDB,
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
          this.waitLoadScreen = false;
          if(authoritation.data.success == true){
            this.waitLoadScreen = false;
            this.valueMutable = true;
            this.titleDialog = "CALIBRADO FINALIZADO";
            this.textDialog = "Se ha calibrado correctamente el dispositivo: " + data.id;
            this.showDialogSuccessful();
          }
          else{
            this.waitLoadScreen = false;
            this.valueMutable = true;
            this.titleDialog = "ERROR INESPERADO";
            this.textDialog = "Ha surgido un error inesperado en la respuesta del calibrado";
            this.showDialogErrorGeneral();
          }
          return authoritation;
        })
        .catch(function(error){
          console.error(error);
          return undefined;
        });
        if(!resultCollection){
          this.waitLoadScreen = false;
          this.valueMutable = true;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado en la colección de calibrado";
          this.showDialogErrorGeneral();
        }
      },      
      loadInfoDeviceSelect(){
        this.waitLoadScreen = true;
        this.textLoader = 'Buscando dispositivos...';
        if(this.selectOrganization.name == 'VER TODOS'){
          this.arrayDevicesOrganizations = this.getDataDevices;
        }
        else{
          let arrayAUXUsers = [];
          this.getDataUsers.forEach(elemUser => {
            if(elemUser.organizationId == this.selectOrganization.id){
              arrayAUXUsers.push(elemUser);
            }
          });
          this.arrayDevicesOrganizations = [];
          arrayAUXUsers.forEach(elemAuxUsers => {
            this.getDataDevices.forEach(elemDev => {
              if(elemDev.userId == elemAuxUsers.id){
                this.arrayDevicesOrganizations.push(elemDev);
              }
            });          
          });
        }
        this.waitLoadScreen = false;
      },
      showInfoDeviceSelect(item){
        this.arrayDevicesOrganizations.forEach(async elem => {
          if(elem.id == item.id){
            this.valueAddress = "";
            this.valueId = elem.id;
            this.valueNameDevice = elem.name;
            this.selectedId = item.id;
            this.$set(item, 'selected', true);
            this.center = elem.position.coordinates;
            // calculate address
            this.getNameAddress(elem.position.coordinates);
          }
        });
      },
      async getNameAddress(posDevice){
        this.collectOrganizations = axios.get(this.UrlGetAddress + posDevice[0] + "&lon="+ posDevice[1] + "&format=json",
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
          this.valueAddress = authoritation.data.display_name;
          return authoritation;
        })
        .catch(function(error){
          console.error(error);
          return undefined;
        });
        if(!this.collectOrganizations){
          this.waitLoadScreen = false;
          this.valueMutable = true;
          this.titleDialog = "ERROR DE DIRECCIÓN";
          this.textDialog = "Ha ocurrido un error con el nombre de la dirección del dispositivo";
          this.showDialogErrorGeneral();
        }
      },
      showStatsDevice(){
        this.$router.push({ name: 'statsDevice', params: { deviceSelect: {id: this.valueId, name: this.valueNameDevice} }})
      },
      typeRolHeiht(){
        if(this.rol == 'admin'){
          this.heightTableDevice = '250px'
        }
        else{
          this.heightTableDevice = '340px'          
        }
      },
      openDialogCalibrate(){
        this.valueMutable = true;
        this.openCalibrate = true;
      },
      // OPEN DIALOGS
      async showDialogSuccessful() {
        try {
          await this.$refs.dialogSuccessful.open(
            this.titleDialog,
            this.textDialog
          )
        } catch (error) {
          console.error(error);
        }
      },
      async showDialogErrorGeneral() {
        try {
          await this.$refs.dialogError.open(
            this.titleDialog,
            this.textDialog
          )
        } catch (error) {
          console.error(error);
        }
      },
      showMapDialog(e){
        this.valueMutable = e;
        this.refreshDevice();
      }
    }
  }
</script>