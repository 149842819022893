<template>
  <div>
    <v-form id="idFormRegister" ref="formRegister" v-model="validRegister" lazy-validation v-on:submit.prevent="noop">
      <v-row class="ma-0 pa-0 mt-8 justify-end">
        <v-col cols="12" sm="12" md="7" lg="7" xl="7" class="ma-0 pa-0">
          <v-row>
            <v-col class="mt-2 mb-10">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0"><v-img src="../assets/img/banner-air-CO2.png" aspect-ratio="5" contain></v-img></v-col>
                <v-col class="ma-0 pa-0"><v-img src="../assets/img/logo_ayuntamiento_salamanca.png" aspect-ratio="5" contain></v-img></v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="bg-co2-title font-co2 font-size-co2-1 text-center">DARSE DE ALTA COMO USUARIO</div>
          <v-text-field
            @keyup.enter="checkRegister"
            id="idNameUserRes"
            type="text"
            v-model="nameUser"
            class="px-12 pt-12"
            color="#00627c"
            label="Introducir nombre de usuario"
            placeholder="Ej: Juán"
            :rules="[rules.required]"
            required
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_user_color.svg">
            </template>
          </v-text-field>
          <v-text-field
            @keyup.enter="checkRegister"
            id="idPasswUserRes"
            v-model="passwUser"
            class="px-12 pt-6"
            color="#00627c"
            label="Introducir contraseña"
            placeholder="Ej: J1234_xdf"
            :append-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassw ? 'text' : 'password'"
            @click:append="showPassw = !showPassw"
            :rules="[rules.required]"
            required
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_password_color.svg">
            </template>
          </v-text-field>
          <v-text-field
            @keyup.enter="checkRegister"
            id="idPasswUserRepitRes"
            v-model="passwUserRepit"
            class="px-12 pt-6"
            color="#00627c"
            label="Volver a introducir la contraseña"
            placeholder="Ej: J1234_xdf"
            :append-icon="showPasswRepit ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPasswRepit ? 'text' : 'password'"
            @click:append="showPasswRepit = !showPasswRepit"
            :rules="[rules.required]"
            required
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_password_repit_color.svg">
            </template>
          </v-text-field>
          <v-text-field
            @keyup.enter="checkRegister"
            id="idEmailUserRes"
            v-model="emailUser"
            class="px-12 pt-6"
            color="#00627c"
            label="Introducir correo electrónico"
            placeholder="Ej: micorreo@gmail.com"
            :rules="[rules.required, rules.email]"
            required
          >
            <template v-slot:prepend>
              <img width="24" height="24" src="../assets/svg/ic_email_color.svg">
            </template>
          </v-text-field>

          <v-row class="d-flex justify-center mt-6">
            <v-col cols="9" sm="9" md="6" lg="9" xl="9">
              <v-btn elevation="0" class="btn-accept font-co2" @click="checkRegister">REGISTRARSE</v-btn>                      
            </v-col>
          </v-row>

          <v-row class="d-flex justify-center mt-2">
            <v-col cols="9" sm="9" md="6" lg="9" xl="9">
              <v-btn elevation="0" class="btn-normal font-co2" @click="goBackLogin">VOLVER</v-btn>                      
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-form>   
    <!-- DIALOG -->
    <DialogError ref="dialogError" /> 
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay>    
  </div>
</template>

<script>
import config from '../config.json';
import axios from "axios";
export default {
  name: 'registerForm',
  data(){
    return{
      urlCo2Organizations: config.host + config.apiUrl + config.urlOrganizations,
      urlCo2Users: config.host + config.apiUrl + config.urlUsers,
      waitLoadScreen: false,
      textLoader: '',
      titleDialog: '',
      textDialog: '',
      validRegister: true,
      nameUser: '',
      passwUser: '',
      showPassw: false,
      passwUserRepit: '',
      showPasswRepit: false,
      emailUser: '',
      listUsers: [],
      rules: {
        required: value => !!value || 'Campo obligatorio',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      errorCatch: '',
    }
  },
  props: {
    tokenRegister: String
  },
  components: {
    DialogError: () => import("../components/DialogError"),
  },
  methods:{
    checkRegister(){
      if (this.tokenRegister != '') {
        if(this.$refs.formRegister.validate()){
          if(this.passwUser == this.passwUserRepit){
            this.loadListUsers();
          }
          else{
            
            this.showDialogErrorPassw();
          }
        }
      }
      else{
        this.titleDialog = 'REGISTRO NO AUTORIZADO';
        this.textDialog = 'El link de autorización para el registro no es válido o ha caducado. Debe solicitar a su Organización un link válido para el registro.';
        this.showDialogErrorUser();
      }
    },    
    async loadListUsers(){
      this.waitLoadScreen = true;
      this.textLoader = "Cargando usuarios...";
      await axios.get(this.urlCo2Users,
        {
          headers:{
            "Content-Type": "application/json"
          }
        }
      )
      .then(authoritation => {
        this.listUsers = authoritation.data;
        this.checkUsers();
        this.waitLoadScreen = false;
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        this.errorCatch = error;
        this.titleDialog = 'ERROR INESPERADO';
        this.textDialog = this.errorCatch;
        this.showDialogErrorGeneral();
        return undefined;
      });
    },
    async checkUsers(){
      let repitedUser = false;
      this.listUsers.forEach(elem => {
        if(elem.name == this.nameUser){
          repitedUser = true;
        }
      });
      if(repitedUser){
        this.titleDialog = 'ERROR DE USUARIO';
        this.textDialog = 'El usuario introducido ya existe y está registrado.';
        this.showDialogErrorUser();
      }
      else{
        this.userRegister();
      }
    },
    async userRegister(){
      this.waitLoadScreen = true;
      this.textLoader = "Guardando usuario...";
      let bodyDB = {
        name: this.nameUser,
        email: this.emailUser,
        password: this.passwUser,
        organizationId: this.tokenRegister
      }
      await axios.post(this.urlCo2Users,
        bodyDB,
        {
          headers:{
            "Content-Type": "application/json"
          }
        }
      )
      .then(authoritation => {
        this.waitLoadScreen = false;
        this.$emit("respondRegisterLogin", { value: true, type:"register", nameUser: this.nameUser });
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        this.errorCatch = error;
        this.titleDialog = 'ERROR INESPERADO';
        this.textDialog = this.errorCatch;
        this.showDialogErrorGeneral();
        return undefined;
      });
    },
    goBackLogin(){
      this.$emit("respondRegisterLogin", { value: true, type:"back" });
    },  
    noop(){},  
    // OPEN DIALOGS
    async showDialogErrorPassw() {
      try {
        await this.$refs.dialogError.open(
          this.titleDialog,
          this.textDialog
        )
      } catch (error) {
        console.error(error);
      }
    },
    async showDialogErrorUser() {
      try {
        await this.$refs.dialogError.open(
          this.titleDialog,
          this.textDialog
        )
      } catch (error) {
        console.error(error);
      }
    },
    async showDialogErrorGeneral() {
      try {
        await this.$refs.dialogError.open(
          this.titleDialog,
          this.textDialog
        )
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>