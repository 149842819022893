<template>
  <div class="mx-6">
    <v-row>
      <v-col>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="table-header-devices">ID</th>
                <th class="table-header-devices text-left">Email</th>
                <th class="table-header-devices text-left">Confirmado</th>
                <th class="table-header-devices text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in arrayInfoEmails" :key="item.id">
                <td class="font-co2 font-size-co2-3">{{ item.id }}</td>
                <td class="font-co2 font-size-co2-3">{{ item.email }}</td>
                <td>
                  <v-tooltip v-model="item.show" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" text>
                        <img v-if="item.validated===true" width="30" height="30" src="../assets/svg/ic_email_true.svg" class="mt-2">
                        <img v-else width="30" height="30" src="../assets/svg/ic_email_false.svg" class="mt-2">
                      </v-btn>
                    </template>
                    <span v-if="item.validated===true">Este email está confirmado por el servidor</span>
                    <span v-else>Confirmar correo a través del email recibido</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-btn elevation="0" class="btn-red font-co2" v-on:click="removeEmail(item, i)">
                    <div class="pos-icon-btn-left"><img width="40" height="40" src="../assets/svg/ic_email_remove.svg"></div>
                    ELIMINAR
                  </v-btn> 
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>    
    <!-- DIALOG -->
    <DialogRemove ref="dialogRemove" @respondRemove="respondRemove" />  
  </div>
</template>

<script>
export default {
  name: 'configEmail',
  data () {
    return {}
  },
  components: {
      DialogRemove: () => import("../components/DialogRemove"),
    },
  props: {
    arrayInfoEmails: Array
  },
  methods:{
    respondRemove(e){
      this.$emit('deleteEmail', e);
    },
    // DIALOGS
    async removeEmail(item, i){
      let info = {item: item, pos: i}
      try {
        await this.$refs.dialogRemove.open(
          info,
          "DAR DE BAJA EMAIL",
          "¿Está seguro de dar de baja la siguiente dirección de correo: "+item.email+"?"
        )
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>