<template>
  <div class="mx-6">
    <v-row class="mt-4">
      <v-col>
        <div>Pulsa el botón <span class="font-co2 font-size-co2-3">"GENERAR INVITACIÓN"</span> para generar una url y poder enviarlo a otros usuarios para que se registren en la plataforma.</div>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="px-8 mt-4">
        <v-btn elevation="0" class="btn-normal font-co2" v-on:click="generateCodeInvite">
          GENERAR INVITACIÓN
        </v-btn> 
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6" xl="6">
        <div v-if="urlInvite != ''" class="font-co2 font-size-co2-3">
          <div class="ml-6">Link activo hasta el: {{ dateAvailable }}</div>
          <v-text-field id="idCopyUrl" v-model="urlInvite" readonly rounded>
            <template slot="append">
              <v-btn :loading="loading" :disabled="loading" @click="loader = 'loading'">
                <div v-if="!checkCopy"><img width="20" height="20" src="../assets/svg/ic_loader_off.svg"></div>
                <div v-else><img width="20" height="20" src="../assets/svg/ic_loader_on.svg"></div>
              </v-btn>               
            </template>
          </v-text-field>
        </div>
      </v-col>
    </v-row>
    <!-- DIALOG -->
    <DialogError ref="dialogError" />
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay> 
  </div>
</template>

<script>
  import config from '../config.json';
  import axios from "axios";
  import { copyToClipBoard } from 'copytoclipboard'
  export default {
    name: 'configOptions1',
    data () {
      return {
        urlDeepEnv: config.hostDeepEnv + config.apiUrl + config.urlOrganizations,
        userLocal: undefined,
        waitLoadScreen: false,
        textLoader: '',
        titleDialog: '',
        textDialog: '',
        loader: null,
        loading: false,
        checkCopy: false,
        urlInvite: '',
        dateAvailable: '',
      }
    },
    components: {
      DialogError: () => import("../components/DialogError"),
    },
    watch: {
      loader () {
        this.checkCopy = false;

        const l = this.loader
        this[l] = !this[l]

        // copy url por
        this.copyTextUrl();
      },
    },
    mounted(){
      this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
    },
    methods:{
    async generateCodeInvite(){
      this.waitLoadScreen = true;
      this.textLoader = "Generando url...";

      this.collectInvite = await axios.get(this.urlDeepEnv + this.userLocal.id + config.urlGenerateLink,
          {
            headers:{
              "Content-Type": "application/json",
              "Authorization": `Bearer ${this.userLocal.token}`
            }
          }
        )
        .then(authoritation => {
          this.waitLoadScreen = false;
          this.getLinkInvite(authoritation.data);
          return authoritation;
        })
        .catch(function(error){
          console.error(error);
          return undefined;
        });
        if(!this.collectInvite){
          this.waitLoadScreen = false;
          this.titleDialog = "ERROR INESPERADO";
          this.textDialog = "Ha surgido un error inesperado al generar la url de invitación.";
          this.showDialogErrorGeneral();
        }
        
      },
      copyTextUrl(){

        let input = document.getElementById("idCopyUrl");
        copyToClipBoard(input)
          .then(() => console.log('✅ it worked ! ("Ctrl/Cmd + V" to paste it)'))
          .catch(e => console.log('❌ something went wrong', e))

        /*
        input.select();
        input.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(input.value);
        */

        this.checkCopy = true;
        this.loading = false
        this.loader = null
      },
      getLinkInvite(data){
        this.urlInvite = data.url;
        let datepart = data.availableUntil.split("T")[0].split("-");
        this.dateAvailable = datepart[2] + "/" +  datepart[1] + "/" +  datepart[0];
        this.checkCopy = false;
      },
      // DIALOG
      async showDialogErrorGeneral() {
        try {
          await this.$refs.dialogError.open(
            this.titleDialog,
            this.textDialog
          )
        } catch (error) {
          console.error(error);
        }
      },
    },
  }
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>