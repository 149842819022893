<template>
  <div class="mt-8">
    <!-- HEADER -->
    <v-row class="primaryCo2 pt-4">
      <v-col class="d-flex align-center ml-10">
        <img width="40" height="40" src="../assets/svg/ic_configuration_white.svg">
        <span class="font-co2 font-size-co2-5 ml-5">CONFIGURACIÓN</span>
      </v-col>
      <v-col v-if="selectOption == 0" cols="3" class="mx-4">
        <v-btn elevation="0" class="btn-white font-co2" v-on:click="openDialogWarm('RESTAURAR CAMBIOS', '¿Desea restablecer todos los cambios?', 'restart')">
          <div class="pos-icon-btn-left"><img width="24" height="24" src="../assets/svg/ic_restart_color.svg"></div>
          RESTAURAR CAMBIOS
        </v-btn> 
      </v-col>
      <v-col v-if="selectOption == 0" cols="3" class="mx-4">
        <v-btn elevation="0" class="btn-accept font-co2" v-on:click="openDialogWarm('GUARDAR CAMBIOS', '¿Desea guardar todos los cambios?', 'save')">
          <div class="pos-icon-btn-left"><img width="24" height="24" src="../assets/svg/ic_save_green.svg"></div>
          GUARDAR CAMBIOS
          <div class="pos-icon-btn-right pr-5">
            <v-badge v-show="numModi != 0" color="#007a00" :content="numModi"></v-badge>
          </div>
        </v-btn> 
      </v-col>
      <v-col v-if="selectOption == 2" cols="3" class="mx-4">
        <v-btn elevation="0" class="btn-accept font-co2" v-on:click="openDialogEmail">
          <div class="pos-icon-btn-left"><img width="40" height="40" src="../assets/svg/ic_email_green.svg"></div>
          AÑADIR CORREO
          <div class="pos-icon-btn-right pr-5">
            <v-badge v-show="numModi != 0" color="#007a00" :content="numModi"></v-badge>
          </div>
        </v-btn> 
      </v-col>
    </v-row>
    <!-- CONTENT -->
    <v-row>
      <v-col cols="3" class="ma-0 pa-0 pt-8 pl-3">
        <div class="d-inline ma-0 pa-0">
          <v-card elevation="0" class="mx-auto pl-8">
            <v-list>
              <v-list-item-group v-model="selectOption" color="primaryCo2" mandatory>
                <v-list-item v-for="(item, i) in itemsOptions" :key="i">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="font-co2 font-size-co2-3" v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </div>
      </v-col>
      <v-col cols="9" class="ma-0 pa-0 pl-2 primaryCo2">
        <div class="white pa-4" :style="{ minHeight: currentHeightDimension }">
          <config-notifications v-if="selectOption == 0" @updateNumChanges="updateNumChanges" :restart="restartAll" @restartFalse="restartFalse" :save="saveAll" @saveInfoNotifications="saveInfoNotifications" :uploadData="configNotis" />
          <config-invite v-if="selectOption == 1" />
          <config-email v-if="selectOption == 2" :arrayInfoEmails="arrayInfoEmails" @deleteEmail="removeEmail" />
          <config-documentation v-if="selectOption == 3" />
        </div>
      </v-col>
    </v-row>
    <!-- DIALOG --> 
    <DialogError ref="dialogError" />
    <DialogWarm ref="dialogWarm" @saveConfig="saveConfig" />
    <dialog-new-email v-model="openNewEmail" @postRegisterEmail="postRegisterEmail" />
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay> 
  </div>
</template>

<script>
import config from '../config.json';
import axios from "axios";
import ConfigNotifications from '../components/ConfigNotifications.vue';
import ConfigInvite from '../components/ConfigInvite.vue';
import ConfigEmail from '../components/ConfigEmail.vue';
import DialogNewEmail from '../components/DialogNewEmail.vue';
import ConfigDocumentation from '../components/ConfigDocumentation.vue';
export default {
  name: 'configuration',
  data () {
    return {
      urlCo2Email: config.host + config.apiUrl + config.urlOrganizations,
      userLocal: undefined,
      waitLoadScreen: false,
      textLoader: '',
      heightDimension: 750,
      titleDialog: '',
      textDialog: '',
      numModi: 0,
      selectOption: 0,
      restartAll: false,
      saveAll: false,
      openNewEmail: false,
      arrayInfoEmails: [],
      configNotis: {
        configNotisAux: [],
        configNotisInitAux: [],
        configActiveNotis: false,        
      },
      itemsOptions: [
        {
          id: 0,
          icon: 'mdi-circle',
          text: 'Notificaciones',
        },
        {
          id: 1,
          icon: 'mdi-circle',
          text: 'Invitaciones',
        },
        {
          id: 2,
          icon: 'mdi-circle',
          text: 'Cuentas de correo',
        },
        {
          id: 3,
          icon: 'mdi-circle',
          text: 'Documentación',
        },
      ],
    }
  },
  components:{
    ConfigNotifications,
    ConfigInvite,
    ConfigEmail,
    DialogNewEmail,
    ConfigDocumentation,
    DialogError: () => import("../components/DialogError"),
    DialogWarm: () => import("../components/DialogWarm"),
  },
  mounted(){
    this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
    this.calculateDimensionView();
    this.getInfoEmails();
  },
  computed: {
    currentHeightDimension() {
      return `${this.heightDimension}px !important`;
    },
  },
  methods:{
    calculateDimensionView(){
      this.heightDimension = screen.height * 0.716;
    },
    restartAllOptions(){
      this.restartAll = true;
    },
    restartFalse(){
      this.restartAll = false;
    },
    saveAllOptions(){
      this.saveAll = true;
    },
    saveFalse(){
      this.saveAll = false;
    },
    updateNumChanges(e){
      this.numModi = e;
    },
    openDialogWarm(title, text, action){
      this.titleDialog = title;
      this.textDialog = text;
      this.showDialogWarm(action);
    },
    saveConfig(e){
      if(e.res == true){
        if(e.action == 'restart'){
          this.restartAllOptions();
        }
        if(e.action == 'save'){
          this.saveAllOptions();
        }
        this.configNotis = {
          configNotisAux: [],
          configNotisInitAux: [],
          configActiveNotis: false,        
        };
      }
    },
    saveInfoNotifications(e){
      this.configNotis.configNotisAux = e.saveNotis;
      this.configNotis.configNotisInitAux = e.saveNotisInit;
      this.configNotis.configActiveNotis = e.active;
    },
    openDialogEmail(){
      this.openNewEmail = true;
    },
    async postRegisterEmail(e){
      this.waitLoadScreen = true;
      this.textLoader = "Guardando email...";
      let bodyDB = e;
      await axios.post(this.urlCo2Email + this.userLocal.id + "/emails",
        bodyDB,
        {
          headers:{
            "Content-Type": "application/json"
          }
        }
      )
      .then(authoritation => {
        this.waitLoadScreen = false;
        this.getInfoEmails();
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        this.waitLoadScreen = false;
        this.titleDialog = "ERROR INESPERADO";
        this.textDialog = "Ha surgido un error inesperado en la colección de alerts";
        this.showDialogErrorGeneral();
        return undefined;
      });
    },
    async getInfoEmails(){
      this.waitLoadScreen = true;
      this.textLoader = "Generando url...";
      this.collectInvite = await axios.get(this.urlCo2Email + this.userLocal.id + "/emails",
        {
          headers:{
            "Content-Type": "application/json",
          }
        }
      )
      .then(authoritation => {
        this.waitLoadScreen = false;
        this.arrayInfoEmails = [];
        for (const item of authoritation.data) {
          this.arrayInfoEmails.push({
            id: item.id,
            email: item.email,
            validated: item.validated,
            show: false,
          });
        }
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        return undefined;
      });

      if(!this.collectInvite){
        this.waitLoadScreen = false;
        this.titleDialog = "ERROR INESPERADO";
        this.textDialog = "Ha surgido un error inesperado al obtener todas las direcciones de email.";
        this.showDialogErrorGeneral();
      }
    },
    async removeEmail(e){
      this.waitLoadScreen = true;
      this.textLoader = 'Borrando notificación...'
      await axios.delete(this.urlCo2Email + this.userLocal.id + "/emails/" + e.item.id,         
      {
        headers:{
          "Content-Type": "application/json",
        }
      })
      .then(authoritation => {
        this.arrayInfoEmails.splice(e.pos, 1);
        return authoritation;
      })
      .catch(function(error){
        console.error(error);
        return undefined;
      });
      this.waitLoadScreen = false;
    },
    // DIALOG
    async showDialogErrorGeneral() {
      try {
        await this.$refs.dialogError.open(
          this.titleDialog,
          this.textDialog
        )
      } catch (error) {
        console.error(error);
      }
    },
    async showDialogWarm(action) {
      try {
        await this.$refs.dialogWarm.open(
          this.titleDialog,
          this.textDialog,
          action
        )
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>