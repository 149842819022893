<template>
  <div>
    <v-navigation-drawer app v-model="drawerNoti" temporary width="360px" right class="body-toolbar-list">
      <!-- notifications -->
      <v-card elevation="0" class="mx-auto bg-card-noti">
        <RecycleScroller
          class="scroller"
          :items="listNotifications"
          :item-size="5"
          key-field="id"
          v-slot="{ item }"
        >
          <v-list-item v-if="item.type=='check'" id="idNotification" class="my-8">
            <v-row class="ml-1">
              <v-col cols="2" class="panel-noti-icon-check">
                <v-img src="../assets/svg/ic_notification_color.svg" aspect-ratio="1" contain></v-img>
              </v-col>
              <v-col class="panel-noti-check">
                <div class="removeNoti"><v-btn text v-on:click="removeNoti(item)"><div class="font-co2 font-size-co2-3">X</div></v-btn></div>
                <div class="text-menu-bar font-co2 font-size-co2-3">{{ item.name }}</div>
                <div class="text-menu-bar font-co2 font-size-co2-3"><small>{{ formatDate(item) }}</small></div>
                <div class="text-menu-bar font-co2-simple font-size-co2-3">{{ item.descrip }}</div>
              </v-col>
            </v-row>
          </v-list-item>
          <!-- 
          <v-list-item v-else-if="item.type=='alert'" id="idNotification" class="my-8">
            <v-row class="ml-4">
              <v-col cols="2" class="panel-noti-icon">
                <v-img src="../assets/svg/ic_notification_red.svg" aspect-ratio="1" contain></v-img>
              </v-col>
              <v-col class="panel-noti-info">
                <div class="removeNoti"><v-btn text v-on:click="removeNoti(item)"><div class="font-co2 font-size-co2-4">X</div></v-btn></div>
                <div class="text-menu-bar-red font-co2 font-size-co2-4">{{ item.name }}</div>
                <div class="text-menu-bar-red font-co2 font-size-co2-4"><small>{{ formatDate(item) }}</small></div>
                <div class="text-menu-bar-red font-co2-simple font-size-co2-4">{{ item.descrip }}</div>
                <v-btn v-show="!item.read" elevation="0" class="btn-red font-co2 mt-4" v-on:click="readNoti(item)">OK</v-btn>
              </v-col>
            </v-row>
          </v-list-item>
          -->          
          <v-list-item v-else id="idNotification" class="my-8">
            <v-row class="ml-1">
              <v-col cols="2" class="panel-noti-icon">
                <v-img src="../assets/svg/ic_notification_color.svg" aspect-ratio="1" contain></v-img>
              </v-col>
              <v-col class="panel-noti-info">
                <div class="removeNoti"><v-btn text v-on:click="removeNoti(item)"><div class="font-co2 font-size-co2-3">X</div></v-btn></div>
                <div class="text-menu-bar font-co2 font-size-co2-3">{{ item.name }}</div>
                <div class="text-menu-bar font-co2 font-size-co2-3"><small>{{ formatDate(item) }}</small></div>
                <div class="text-menu-bar font-co2-simple font-size-co2-3">{{ item.descrip }}</div>
                <v-btn v-show="!item.read" elevation="0" class="btn-normal font-co2 mt-4" v-on:click="readNoti(item)">OK</v-btn> 
              </v-col>
            </v-row>
          </v-list-item>
        </RecycleScroller>
      </v-card>
      <!-- options notifications -->
      <v-layout>
        <v-flex class="sidebar-noti-options">
          <v-row class="mx-2">
            <v-col>
              <v-btn elevation="0" class="btn-white font-co2" v-on:click="readAllNoti">
                <div class="pos-icon-btn-left"><img width="24" height="24" src="../assets/svg/ic_read_color.svg"></div>
                LEER TODAS
              </v-btn> 
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col>
              <v-btn elevation="0" class="btn-white font-co2" v-on:click="removeAllNoti">
                <div class="pos-icon-btn-left"><img width="24" height="24" src="../assets/svg/ic_remove_color.svg"></div>
                ELIMINAR TODAS
              </v-btn> 
            </v-col>
          </v-row>
          <v-row class="mx-2">
            <v-col>
              <v-btn elevation="0" class="btn-white font-co2" v-on:click="drawerNoti = false">
                <div class="pos-icon-btn-right"><img width="24" height="24" src="../assets/svg/ic_arrow_color.svg"></div>
                VOLVER
              </v-btn> 
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>

    </v-navigation-drawer>      
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller' // https://github.com/Akryum/vue-virtual-scroller
export default {
  name: 'SideBarNotifications',
  data(){
    return{
      listNotifications: [],
      benched: 0,
      lastReadDate: '',
    }
  },
  props: {
    value: Boolean
  },
  components: {
    RecycleScroller
  },
  computed: {
    drawerNoti: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    }, 
  },
  mounted(){
    this.loadListNoti();
  },
  methods: {
    loadListNoti(){
      this.listNotifications = [
        {
          id: '0000017c7f3a4bfc-34333cb7-2a7d5fbf-1ab0c90c',
          created: '2021-10-18T08:10:29.951Z',
          modify: '2021-10-18T08:11:01.246Z',
          name: 'CO2 alto',
          descrip: 'Alerta, concentración de CO2 elevada',
          type: 'normal',
          read: false,
        },
        {
          id: '0000017c7f3a4bf5-d3d19343-3998f5d9-a0725d1f',
          created: '2021-10-18T08:09:09.692Z',
          modify: '2021-10-18T08:09:57.674Z',
          name: 'CO2 moderado',
          descrip: 'Cantidad de CO2 ambiente moderada',
          type: 'normal',
          read: false,
        },
        {
          id: '0000017c7f3a4bf5-3998f5d9-d3d14673-a0725d1f',
          created: '2021-10-15T08:09:09.692Z',
          modify: '2021-10-16T08:09:57.674Z',
          name: 'Actualización de datos 1',
          descrip: 'Se ha modificado el nombre de la ubicación en: “Dispositivo 2 Zara”',
          type: 'normal',
          read: false,
        },
        {
          id: '0000017c7f3a4bf5-4857fy45-0385y26n-12834k8z',
          created: '2021-10-14T08:09:09.692Z',
          modify: '2021-10-14T08:09:57.674Z',
          name: 'CO2 alto 2',
          descrip: 'Alerta, concentración de CO2 elevada',
          type: 'normal',
          read: false,
        },
        {
          id: '0000017c7f3a4bf5-367g34c7-q3593h75-24f89i563',
          created: '2021-09-20T08:09:09.692Z',
          modify: '2021-09-20T08:09:57.674Z',
          name: 'Actualización de datos 2',
          descrip: 'Se ha modificado el nombre de la ubicación en: “Dispositivo 4 Zara”',
          type: 'normal',
          read: false,
        },
        {
          id: '0000017c7f3a4bf5-4h6465j7-7543d3w3-1qa23574',
          created: '2021-10-12T08:09:09.692Z',
          modify: '2021-10-12T08:09:57.674Z',
          name: 'Actualización de datos 3',
          descrip: 'Se ha modificado el nombre de la ubicación en: “Dispositivo 5 Zara”',
          type: 'normal',
          read: false,
        },
      ];
      this.lastFiveDays(this.listNotifications);
    },
    lastFiveDays(list){
      let todayDate = new Date();
      let dateLast5=new Date(todayDate);
      dateLast5.setDate(dateLast5.getDate() - 5);
      this.getLastReadDate(dateLast5); // last read date
      let date = '';
      let arrayAux = [];
      for (const elem of list) {
        if(elem.modify != undefined && elem.modify != ''){
          date = new Date(elem.modify);
        }
        else{
          date = new Date(elem.created);
        }
        if(date > dateLast5){
          arrayAux.push(elem);
        }
      }
      this.listNotifications = arrayAux;
      
    },
    getLastReadDate(dateLast5){
      if(this.lastReadDate == ''){
        let dateLast5Format = dateLast5.getFullYear() + "-" + (dateLast5.getMonth() + 1) + "-" + dateLast5.getDate() + "T00:00:00.000Z";
        this.lastReadDate = dateLast5Format;
      }
    },
    removeAllNoti(){
      this.listNotifications = []
    },
    readAllNoti(){
      for (const i in this.listNotifications) {
        this.listNotifications[i].read = true;
        this.listNotifications[i].type = 'check';
      }
    },
    removeNoti(item){
      let arrayAux = [];
      for (let elem of this.listNotifications) {
        if(elem.id != item.id){
          arrayAux.push(elem);
        }
      }
      this.listNotifications = arrayAux;
      /*
      for (const [i, elem] of this.listNotifications.entries()) {
        if(elem.id == item.id) {
          this.listNotifications.splice(i, 1);
        }
      }
      */
    },
    readNoti(item){
      for (const [i, elem] of this.listNotifications.entries()) {
        if(elem.id == item.id){
          this.listNotifications[i].read = true;
          this.listNotifications[i].type = 'check';
          this.listNotifications.push(this.listNotifications.splice(i, 1)[0]);
          this.lastReadDate = elem.modify;
          this.updateReadNoti();
          break;
        }
      }
    },
    updateReadNoti(){
      let updateLast = new Date(this.lastReadDate);
      for (const [i, elem] of this.listNotifications.entries()) {
        let date = new Date(elem.modify);
        if(date <= updateLast){
          this.listNotifications[i].read = true;
          this.listNotifications[i].type = 'check';
          this.listNotifications.push(this.listNotifications.splice(i, 1)[0]);
        }
      }
    },
    formatDate(date){
      if(date.modify != undefined && date.modify != ''){
        let partD = date.modify.split("T")[0].split("-");
        let partT = date.modify.split("T")[1].split("Z")[0];
        return partD[2] + "-" + partD[1] + "-" + partD[0] + " -> " + partT;
      }
      else{
        let partD = date.created.split("T")[0].split("-");
        let partT = date.modify.split("T")[1].split("Z")[0];
        return partD[2] + "-" + partD[1] + "-" + partD[0] + " -> " + partT;
      }
    }
  }
}
</script>