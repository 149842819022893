import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import ContentHome from '../views/ContentHome.vue'
import DeviceManager from '../views/DeviceManager.vue'
import Stats from '../views/Stats.vue'
import Configuration from '../views/Configuration.vue'
import StatsDevice from '../views/StatsDevice.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      enterClass: 'animate__animated animate__fadeIn',
      leaveClass: 'animate__animated animate__slideOutUp'
    }
  },
  {
    path: '/home',
    component: Home,
    meta: {
      enterClass: 'animate__animated animate__fadeIn',
      leaveClass: 'animate__animated animate__slideOutUp',
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: ContentHome,
        meta: {
          enterClass: 'animate__animated animate__fadeIn',
          leaveClass: 'animate__animated animate__slideOutUp',
          requiresAuth: true,
        },
      },
      {
        path: "deviceManager",
        component: DeviceManager,
        requiresAuth: true,
      },
      {
        path: "stats",
        component: Stats,
        requiresAuth: true,
      },
      {
        path: "configuration",
        component: Configuration,
        requiresAuth: true,
      },
      {
        name: "statsDevice",
        path: "statsDevice",
        component: StatsDevice,
        requiresAuth: true,
        props: true
      },
    ]
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  const currentUser = window.localStorage.infoUser ? JSON.parse(window.localStorage.infoUser).token : undefined;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if(requiresAuth && !currentUser){
    next("/");
  }
  else next();

});

export default router
