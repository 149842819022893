<template>
  <div>
    <v-navigation-drawer app v-model="drawer" temporary width="360px" class="body-toolbar-list">
      <v-layout>
        <!-- logo -->
        <v-flex class="header-toolbar-list">
          <v-row>
            <v-col cols="2" class="pl-4 my-auto">
              <v-btn text style="height: 100% !important" @click="drawer = false"><v-img width="20px" height="50px" src="../assets/svg/ic_arrow_color_invert.svg"></v-img></v-btn>
            </v-col>
            <v-col>
              <router-link to="/home"><v-img src="../assets/img/banner-air-CO2.png" aspect-ratio="5" contain @click="drawer = false"></v-img></router-link>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>

      <v-list dense class="pt-5">
        <!-- Home -->
        <v-list-item id="idIconSidebarHome" class="my-10" link to="/home" @click="showMap">
          <v-row class="ml-4">
            <v-col cols="3">
              <v-img src="../assets/svg/ic_location_company_white.svg" aspect-ratio="1" contain></v-img>
            </v-col>
            <v-col class="my-auto">
              <div class="text-menu-bar font-co2 font-size-co2-1">Inicio</div>
            </v-col>
          </v-row>
        </v-list-item>
        <!-- Device -->
        <v-list-item id="idIconSidebarHome" class="my-10" link to="/home/deviceManager">
          <v-row class="ml-4">
            <v-col cols="3">
              <v-img src="../assets/svg/ic_device_white.svg" aspect-ratio="1" contain></v-img>
            </v-col>
            <v-col>
              <div class="text-menu-bar font-co2 font-size-co2-1">Administración</div>
              <div class="text-menu-bar font-co2 font-size-co2-1 ma-0 pa-0">de dispositivos</div>
            </v-col>
          </v-row>
        </v-list-item>
        <!-- Stats -->
        <v-list-item id="idIconSidebarHome" class="my-10" link to="/home/stats">
          <v-row class="ml-4">
            <v-col cols="3">
              <v-img src="../assets/svg/ic_stats_white.svg" aspect-ratio="1" contain></v-img>
            </v-col>
            <v-col class="my-auto">
              <div class="text-menu-bar font-co2 font-size-co2-1">Estadísticas</div>
              <div class="text-menu-bar font-co2 font-size-co2-1 ma-0 pa-0">generales</div>
            </v-col>
          </v-row>
        </v-list-item>
        <!-- Configuration -->
        <v-list-item v-if="rol=='admin' || rol=='organization'" id="idIconSidebarHome" class="my-10" link to="/home/configuration">
          <v-row class="ml-4">
            <v-col cols="3">
              <v-img src="../assets/svg/ic_configuration_white.svg" aspect-ratio="1" contain></v-img>
            </v-col>
            <v-col class="my-auto">
              <div class="text-menu-bar font-co2 font-size-co2-1">Configuración</div>
            </v-col>
          </v-row>
        </v-list-item>
        <!-- Logout -->
        <v-list-item id="idIconSidebarHome" class="my-10" @click="goLogout">
          <v-row class="ml-4">
            <v-col cols="3">
              <v-img src="../assets/svg/ic_logout_white.svg" aspect-ratio="1" contain></v-img>
            </v-col>
            <v-col class="my-auto">
              <div class="text-menu-bar font-co2 font-size-co2-1">Cerrar sesión</div>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>   
    <!-- DIALOG -->
    <DialogLogout ref="dialogLogout" @controlShowMap="showMap" />   
  </div>
</template>

<script>
export default {
  name: 'SideBarMenu',
  data(){
    return{
      flagOpenDialog: false,
      userLocal: undefined,
      rol: ''
    }
  },
  props: {
    value: Boolean
  },
  components: {
    DialogLogout: () => import("../components/DialogLogout"),
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        if(this.flagOpenDialog == false){
          this.$emit("input", value);
        }
      },
    },    
  }, 
  mounted(){
    this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
    this.rol = this.userLocal.rol;
  },
  methods: {
    goLogout(){
      this.showDialogLogout(); // show modal dialog Logout
    },
    async showDialogLogout() {
      this.flagOpenDialog = true;
      try {
        await this.$refs.dialogLogout.open(
          "CERRAR SESIÓN",
          "¿Está seguro de abandonar la sesión de usuario actual?"
        )
      } catch (error) {
        console.error(error);
      }
    },
    showMap(){
      this.$emit("input", false);
    }
  }
}
</script>