<template>
  <div class="mx-6">
    <v-row class="mt-6">
      <v-col>
        <v-btn elevation="0" class="btn-normal font-co2" @click="downloadDocWeb"> 
          <div class="mr-4"><img width="30" height="30" src="../assets/svg/ic_doc_web.svg"></div>
          DESCARGAR MANUAL WEB
        </v-btn> 
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col>
        <v-btn elevation="0" class="btn-normal font-co2" @click="downloadDocDevice"> 
          <div class="mr-4"><img width="30" height="30" src="../assets/svg/ic_doc_device.svg"></div>
          DESCARGAR MANUAL DEL DISPOSITIVO
        </v-btn> 
      </v-col>
    </v-row>
    <!-- DIALOG -->
    <DialogError ref="dialogError" />
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay> 
  </div>
</template>

<script>
  import config from '../config.json';
  
  export default {
    name: 'configDocumentation',
    data () {
      return {
        urlDeepEnv: config.hostDeepEnv + config.apiUrl,
        urlDeepEnvDocUser: config.host + config.apiUrl + config.urlDocumentsUser,
        urlDeepEnvDocDevice: config.host + config.apiUrl + config.urlDocumentsDevice,
        userLocal: undefined,
        waitLoadScreen: false,
        textLoader: '',
        titleDialog: '',
        textDialog: '',
      }
    },
    components: {
      DialogError: () => import("../components/DialogError"),
    },
    mounted(){
      this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
    },
    methods:{
      async downloadDocWeb(){
        this.waitLoadScreen = true;
        this.textLoader = "Descargando...";
        window.open(this.urlDeepEnvDocUser, "_blank")
        this.waitLoadScreen = false;
      },
      async downloadDocDevice(){
        this.waitLoadScreen = true;
        this.textLoader = "Descargando...";
        window.open(this.urlDeepEnvDocDevice, "_blank")
        this.waitLoadScreen = false;
      }
    }
  }
</script>